import React from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';
import Parser from 'html-react-parser';

import Table from '../table/Table';
import Confirmation from '../confirmation/Confirmation';
import Overlay from '../overlay/Overlay';
import EditInfo from './edit_info/EditInfo';
import EditSection from './edit_section/EditSection';
import CreateSection from './create_section/CreateSection';
import NotFound from '../../not_found/NotFound';
import JWTManager from '../../../utils/JWTManager';
import FormatManager from '../../../utils/FormatManager';
import PAGES from '../../../constants/pages.enum';
import IMAGES_POSITION from '../../../constants/images_position.enum';
import './Page.scss';

class Page extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            page: null,
            sections_id: null,
            show_edit_info: false,
            show_delete: false,
            section_delete_id: null,
            show_edit: false,
            show_create: false
        }
    }

    componentDidMount() {
        this.checkPath();
        setTimeout(() => this.setState({ hidden: false }), 1);
        if (JWTManager.getToken() !== null) this.loadData();
        animateScroll.scrollTo(0, {
            duration: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) this.checkPath();
        if (prevProps.page_type !== this.props.page_type) {
            this.setState(
                { hidden: true },
                () => setTimeout(() => this.setState({ hidden: false }), 1)
            );
            if (JWTManager.getToken() !== null) this.loadData();
            animateScroll.scrollTo(0, {
                duration: 0
            });
        }
    }

    checkPath = () => {
        this.setState({ 
            show_edit_info: this.props.location.pathname.includes('/edit_info'),
            show_delete: this.props.location.pathname.includes('/delete'),
            show_edit: this.props.location.pathname.includes('/edit'),
            show_create: this.props.location.pathname.includes('/create')
        });
        this.props.onFullscreenChange(
            this.props.location.pathname.includes('/edit_info') ||
            this.props.location.pathname.includes('/create') ||
            this.props.location.pathname.includes('/edit') ||
            this.props.location.pathname.includes('/delete')
        );
    }

    loadData = () => {
        // Récupération des slides
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/pages/by-page/' + this.props.page_type
        }).then((response) => {
            if (!response) {
                this.setState({
                    page: null,
                    sections_id: null
                });
            }
            if (response.data) {
                this.setState({
                    page: response.data,
                    sections_id: this.getSectionsId(response.data)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getSectionsId = (page) => {
        if (page.sections) return page.sections.map(section => section._id);
        return null;
    }

    getPageName = () => {
        switch (this.props.page_type) {
            case PAGES.HOME:
                return 'Page d\'Accueil';
            case PAGES.CHALET:
                return 'Le Chalet';
            case PAGES.ACTIVITIES:
                return 'Activités';
            case PAGES.LEGAL_NOTICE:
                return 'Mentions légales';
            case PAGES.TERMS_OF_SALES:
                return 'Conditions générales de vente'      
            default:
                return '';
        }
    }

    getInfoIcon = () => {
        switch (this.props.page_type) {
            case PAGES.CHALET:
                return 'cottage';
            case PAGES.ACTIVITIES:
                return 'local_activity';
            default:
                return '';
        }
    }

    getColumns = () => {
        if (this.props.page_type === PAGES.LEGAL_NOTICE || this.props.page_type === PAGES.TERMS_OF_SALES) {
            return [
                {
                    Header: 'Texte',
                    id: 'text',
                    className: 'text main-text',
                    disableSortBy: true,
                    Cell: tableProps => (tableProps.row.original.text && Parser(tableProps.row.original.text)) || null
                }, {
                    Header: '',
                    id: 'edit-column',
                    disableSortBy: true,
                    className: 'actions',
                    Cell: tableProps => <div className="action-buttons">
                        <button className="switch-button" onClick={() => this.onSwitchClick(tableProps.row.original._id)} disabled={!this.canPrevious(tableProps.row.original._id)}>
                            <Icon fontSize="tiny">arrow_upward</Icon>
                        </button>
                        <button className="switch-button" onClick={() => this.onSwitchClick(tableProps.row.original._id, false)} disabled={!this.canNext(tableProps.row.original._id)}>
                            <Icon fontSize="tiny">arrow_downward</Icon>
                        </button>
                        <Link to={this.props.match.url + "/" + tableProps.row.original._id + "/edit"} className="edit-button">
                            <Icon fontSize="tiny">edit</Icon><span>Modifier</span>
                        </Link>
                        <Link to={this.props.match.url + "/" + tableProps.row.original._id + "/delete"} className="delete-button">
                            <Icon fontSize="tiny">delete</Icon><span>Supprimer</span>
                        </Link>
                    </div>
                }
            ];
        } else {
            return [
                {
                    Header: 'Texte',
                    id: 'text',
                    className: 'text main-text',
                    disableSortBy: true,
                    Cell: tableProps => (tableProps.row.original.text && Parser(tableProps.row.original.text)) || null
                }, {
                    Header: 'Images',
                    id: 'images',
                    disableSortBy: true,
                    className: 'section-images',
                    Cell: tableProps => 
                        <div>
                            {
                                tableProps.row.original.images
                                && tableProps.row.original.images.map((image, i) => 
                                    <Link key={image._id} to={"/images/" + image._id + "/edit"}><img 
                                        srcSet={FormatManager.imageSrcSet(image.paths)}
                                        sizes={ Math.round(8 * image.aspect_ratio * 10) / 10 + "rem" }
                                        src={process.env.REACT_APP_API_URL + image.paths.w1920} 
                                        alt='' 
                                    /></Link>
                                )
                            }
                        </div>
                }, {
                    Header: 'Position des images',
                    id: 'images_position',
                    Cell: tableProps => tableProps.row.original.images_position === IMAGES_POSITION.LEFT 
                        ? 'Gauche'
                        : tableProps.row.original.images_position === IMAGES_POSITION.UNDER
                            ? 'En-dessous'
                            : tableProps.row.original.images_position === IMAGES_POSITION.RIGHT
                                ? 'Droite'
                                : null
                }, {
                    Header: '',
                    id: 'edit-column',
                    disableSortBy: true,
                    className: 'actions',
                    Cell: tableProps => <div className="action-buttons">
                        <button className="switch-button" onClick={() => this.onSwitchClick(tableProps.row.original._id)} disabled={!this.canPrevious(tableProps.row.original._id)}>
                            <Icon fontSize="tiny">arrow_upward</Icon>
                        </button>
                        <button className="switch-button" onClick={() => this.onSwitchClick(tableProps.row.original._id, false)} disabled={!this.canNext(tableProps.row.original._id)}>
                            <Icon fontSize="tiny">arrow_downward</Icon>
                        </button>
                        <Link to={this.props.match.url + "/" + tableProps.row.original._id + "/edit"} className="edit-button">
                            <Icon fontSize="tiny">edit</Icon><span>Modifier</span>
                        </Link>
                        <Link to={this.props.match.url + "/" + tableProps.row.original._id + "/delete"} className="delete-button">
                            <Icon fontSize="tiny">delete</Icon><span>Supprimer</span>
                        </Link>
                    </div>
                }
            ];
        }
    }

    switchSections = (current_id, other_id) => {
        // Inversion des slides
        const id = toast.loading('Inversion des sections...', { toastId: 'switch-sections-' + current_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/sections/switch-order',
            data: {
                current_id: current_id,
                other_id: other_id
            }
        }).then((response) => {
            if (response.data.success) {
                toast.update(id, {
                    render: 'Sections inversées avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
            }
        }).catch((error) => {
            console.error(error.response);
            toast.update(id, {
                render: 'Impossible d\'inverser les sections',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000
            });
        });
    }

    onSwitchClick = (row, up = true) => {
        if (up) {
            const other = this.getPreviousRow(row);
            if (other) this.switchSections(row, other);
        } else {
            const other = this.getNextRow(row);
            if (other) this.switchSections(row, other);
        }
    }

    getPreviousRow = (row) => {
        return this.state.sections_id[this.state.sections_id.indexOf(row) - 1];
    }

    getNextRow = (row) => {
        return this.state.sections_id[this.state.sections_id.indexOf(row) + 1];
    }

    canPrevious = (row) => {
        return this.state.sections_id.indexOf(row) !== 0;
    }

    canNext = (row) => {
        return this.state.sections_id.indexOf(row) !== this.state.sections_id.length - 1;
    }

    onEditInfoClose = () => {
        this.setState({ show_edit_info: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onEditInfoSubmit = () => {
        this.setState({ show_edit_info: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onDeleteOpen = (row) => {
        this.setState({
            show_delete: true,
            section_delete_id: row
        });
        this.props.onFullscreenChange(true);
    }

    onDeleteAnnulation = () => {
        this.setState({
            show_delete: false,
            section_delete_id: null
        });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onDeleteConfirmation = () => {
        // Suppression de la slide
        const id = toast.loading('Suppression de la section...', { toastId: 'delete-section-' + this.state.section_delete_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/auth/sections/' + this.state.section_delete_id,
        }).then((response) => {
            if (response.data.success) {
                this.setState({
                    show_delete: false,
                    section_delete_id: null
                });
                this.props.onFullscreenChange(false);
                toast.update(id, {
                    render: 'Section supprimée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
                setTimeout(() => this.props.history.push(this.props.match.url), 400);
            }
        }).catch((error) => {
            console.error(error.response);
            toast.update(id, {
                render: 'Impossible de supprimer la section',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000
            });
        });
    }

    onEditClose = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onEditSubmit = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateClose = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateSubmit = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    render() {
        return (
            <div className={this.state.hidden ? "Page hidden" : "Page"}>
                <h2 className={this.state.hidden ? "hidden" : ""}>{this.getPageName()}</h2>
                {
                    [PAGES.CHALET, PAGES.ACTIVITIES].includes(this.props.page_type)
                    && <div className="page-content">
                        <div className="page-info">
                            <div className="block-heading">
                                <h3><Icon fontSize="small">{this.getInfoIcon()}</Icon><span>Informations de la page</span></h3>
                                <Link to={this.props.match.url + "/edit_info"} className="create-button">
                                    <Icon fontSize="small">edit</Icon><span>Modifier les informations</span>
                                </Link>
                            </div>
                            <div className="block-content">
                                {
                                    this.state.page === null
                                    && <p>Aucune information</p>
                                }
                                {
                                    this.state.page
                                    && <div className="info">
                                        <div className="element base-info">
                                            <div>
                                                <Icon fontSize="small">title</Icon>
                                                <span>{this.state.page.title}</span>
                                            </div>
                                            <div className="desc-container">
                                                <Icon fontSize="small">description</Icon>
                                                <div className="desc">{this.state.page && this.state.page.desc && Parser(this.state.page.desc)}</div>
                                            </div>
                                        </div>
                                        {
                                            this.state.page.header_image    
                                            && <div className="element">
                                                <div className="image-container">
                                                    <Icon fontSize="small">image</Icon>
                                                    <Link to={"/images/" + this.state.page.header_image._id + "/edit" }><img 
                                                        srcSet={FormatManager.imageSrcSet(this.state.page.header_image.paths)}
                                                        sizes={ Math.round(18 * this.state.page.header_image.aspect_ratio * 10) / 10 + "rem" }
                                                        src={this.state.page.header_image.paths.w1920} 
                                                        alt="Entête"
                                                    /></Link>
                                                </div>
                                            </div>
                                        }
                                    </div>
                                }
                            </div>
                        </div>
                    </div>
                }
                <div className="sections-content">
                    <div className="sections-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">library_books</Icon><span>Toutes les sections</span></h3>
                            <Link to={this.props.match.url + "/create"} className="create-button">
                                <Icon fontSize="small">post_add</Icon><span>Ajouter une section</span>
                            </Link>
                        </div>
                        <div className="block-content">
                            {
                                (this.state.page === null || this.state.page.sections === null || this.state.page.sections.length === 0)
                                && <p>Aucune section</p>
                            }
                            {
                                this.state.page && this.state.page.sections && this.state.page.sections.length > 0
                                && 
                                <Table data={this.state.page.sections} columns={this.getColumns()} pageSize={5} />
                            }
                        </div>
                    </div>
                </div>
                <Switch>
                    {
                        [PAGES.CHALET, PAGES.ACTIVITIES].includes(this.props.page_type)
                        && <Route path={this.props.match.path + "/edit_info"} exact>
                            <Overlay
                                title="Modification des informations de la page"
                                onClose={this.onEditInfoClose}
                                show_overlay={this.state.show_edit_info}
                            >
                                <EditInfo 
                                    page={this.state.page}
                                    onSubmit={this.onEditInfoSubmit}
                                />
                            </Overlay>
                        </Route>
                    }
                    <Route path={this.props.match.path + "/:sectionId/edit"} exact>
                        <Overlay
                            title="Modification de la section"
                            onClose={this.onEditClose}
                            show_overlay={this.state.show_edit}
                        >
                            <EditSection
                                onSubmit={this.onEditSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/:elementId/delete"} exact>
                        <Confirmation
                            title="Voulez-vous supprimer cette section ?"
                            onOpen={this.onDeleteOpen}
                            onCancel={this.onDeleteAnnulation}
                            onConfirm={this.onDeleteConfirmation}
                            show_confirmation={this.state.show_delete}
                        />
                    </Route>
                    <Route path={this.props.match.path + "/create"} exact>
                        <Overlay 
                            title="Nouvelle section"
                            onClose={this.onCreateClose}
                            show_overlay={this.state.show_create}
                        >
                                <CreateSection
                                    onSubmit={this.onCreateSubmit}
                                    page_type={this.props.page_type}
                                />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/*"}>
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Page);