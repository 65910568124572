import React, { createRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';
import moment from 'moment';

import DatePickerField from '../../../../utils/date_picker_field/DatePickerField';
import SelectField from '../../../../utils/SelectField';
import JWTManager from '../../../../utils/JWTManager';
import FormatManager from '../../../../utils/FormatManager';
import './CreatePeriod.scss';

class CreatePeriod extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pricings: null,
            pricings_options: null,
            inavailable_dates: null,
            error_response: null,
            start_period: null,
            end_period: null
        }

        this.endPeriodCreate = createRef();
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null) {
            this.loadData();
            this.getInavailableDates();
        }
    }

    loadData = () => {
        // Récupération des tarifs
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/pricings'
        }).then((response) => {
            if (!response) {
                this.setState({
                    pricings: null,
                    pricings_options: null
                });
            }

            if (response.data) {
                this.setState({
                    pricings: response.data,
                    pricings_options: this.getPricingsOptions(response.data)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getPricingsOptions = (pricings) => {
        return pricings.map(pricing => ({
            value: pricing.id,
            label: <>
                {pricing.name + ' '}  
                <i>
                    (semaine : {FormatManager.priceFormat(pricing.week_pricing)}, week-end : {FormatManager.priceFormat(pricing.weekend_pricing)}{pricing.week_only && ', semaine uniquement'}{pricing.week_reduction && ', réduction semaine : ' + pricing.week_reduction + ' %'}{pricing.week_special && ', semaine spéciale'})
                </i>
            </>
        }));
    }

    getInavailableDates = () => {
        // Récupération des dates non disponibles
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/periods/inavailable-dates'
        }).then((response) => {
            if (!response) {
                this.setState({
                    inavailable_dates: null
                });
            }

            if (response.data) {
                this.setState({
                    inavailable_dates: FormatManager.datesArray(response.data.inavailableDates)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    onSubmit = (period) => {
        const id = toast.loading('Création de la période...', { toastId: 'create-period' });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/periods/create',
            data: {
                start_period: FormatManager.dateDBFormat(period.start_period),
                end_period: FormatManager.dateDBFormat(period.end_period),
                pricing: period.pricing.value
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null,
                    start_period: null,
                    end_period: null
                });
                toast.update(id, {
                    render: 'Période créée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de créer la période',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    showStartMonth = () => {
        if (!this.state.start_period) return;
        if (moment(this.state.end_period).diff(moment(this.state.start_period), 'months') < 1) this.endPeriodCreate.current.getDayPicker().showMonth(this.state.start_period);
    }
    
    handleStartChange = (start_period) => {
        this.setState({ start_period: start_period });
    }
    
    handleEndChange = (end_period) => {
        this.setState({ end_period: end_period }, this.showStartMonth);
    }

    render() {
        const { start_period, end_period } = this.state;
        const modifiers = { start: start_period, end: end_period };

        return (
            <div className="CreatePeriod">
                <Formik
                    initialValues={{
                        start_period: "",
                        end_period: "",
                        pricing: ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            start_period: Yup.date()
                                .transform(FormatManager.parseDateString)
                                .typeError("Veuillez saisir une date")
                                .required("Début de période requis"),
                            end_period: Yup.date("Veuillez saisir une date")
                                .transform(FormatManager.parseDateString)
                                .typeError("Veuillez saisir une date")
                                .required("Fin de période requis"),
                            pricing: Yup.object()
                                .required("Tarif requis")
                        })
                    }
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched }) => (
                        <Form className="create-form" autoComplete="off">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Début de période*</label>
                                    <div className={touched.start_period && errors.start_period ? "field error" : "field"}>
                                        <Icon fontSize="small">today</Icon>
                                        <DatePickerField
                                            name="start_period"
                                            value={start_period}
                                            dayPickerProps={{ 
                                                selectedDays: [start_period, { from: start_period, to: end_period }],
                                                disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: new Date(), after: end_period }) : [],
                                                month: new Date(),
                                                fromMonth: new Date(),
                                                toMonth: end_period,
                                                modifiers: modifiers,
                                                onDayClick: () => this.endPeriodCreate.current.getInput().focus(),
                                                className: "dates-range"
                                            }}
                                            onDayChange={this.handleStartChange}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="start_period" />
                                </div>
                                <div className="form-group">
                                    <label>Fin de période*</label>
                                    <div className={touched.end_period && errors.end_period ? "field error" : "field"}>
                                        <Icon fontSize="small">today</Icon>
                                        <DatePickerField
                                            name="end_period"
                                            ref={this.endPeriodCreate}
                                            value={end_period}
                                            dayPickerProps={{ 
                                                selectedDays: [start_period, { from: start_period, to: end_period }],
                                                disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: start_period }) : [],
                                                modifiers: modifiers,
                                                month: start_period,
                                                fromMonth: start_period,
                                                className: "dates-range"
                                            }}
                                            onDayChange={this.handleEndChange}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="end_period" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Tarif*</label>
                                    <div className={touched.pricing && errors.pricing ? "field error" : "field"}>
                                        <Icon fontSize="small">payment</Icon>
                                        <SelectField
                                            className="select"
                                            name="pricing"
                                            options={this.state.pricings_options}
                                            placeholder="Choisir un tarif..."
                                            noOptionsMessage={() => "Aucun tarif"} />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="pricing" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">save</Icon><span>Enregistrer</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default CreatePeriod;