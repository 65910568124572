import React from 'react';
import { Link, withRouter } from 'react-router-dom';
import Icon from '@mui/material/Icon';

import ROLES from '../../../../constants/roles.enum';
import './MenuItem.scss';

class MenuItem extends React.Component {
    getItemClassName = (className) => {
        return this.props.location.pathname === '/' 
            && this.props.location.pathname === className
            ? 'active MenuItem' : this.props.location.pathname.includes(className)
            && this.props.location.pathname !== '/' && className !== '/' ? 'MenuItem active' : 'MenuItem';
    }

    canAccess = () => {
        if (! this.props.user) return false;
        if (this.props.user.role === ROLES.ADMIN) return true;
        if (this.props.path === '/utilisateurs') return this.props.user.role === ROLES.ADMIN;
        return this.props.user.role === ROLES.ADMIN || this.props.user.role === ROLES.EDITOR;
    }

    render() {
        if (this.canAccess()) {
            return (
                <li className={this.getItemClassName(this.props.path)}>
                    <Link to={this.props.path} onClick={() => this.props.setMenuOpen(false)}><div><Icon fontSize="tiny">{this.props.icon}</Icon><span>{this.props.children}</span></div></Link>
                </li>
            );
        }
        return null;
    }
}

export default withRouter(MenuItem);
