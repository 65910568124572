import React, { createRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, ErrorMessage } from 'formik';
import Icon from '@mui/material/Icon';
import * as Yup from 'yup';
import moment from 'moment';

import DatePickerField from '../../../../utils/date_picker_field/DatePickerField';
import JWTManager from '../../../../utils/JWTManager';
import FormatManager from '../../../../utils/FormatManager';
import './CreateLockedEvent.scss';

class CreateLockedEvent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error_response: null,
            inavailable_dates: null,
            start: this.props.create_start,
            end: this.props.create_end
        }

        this.endCreate = createRef();
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null) this.getInavailableDates();
    }

    getInavailableDates = () => {
        // Récupération des dates non disponibles
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/events/inavailable-dates'
        }).then((response) => {
            if (!response) {
                this.setState({
                    inavailable_dates: null
                });
            }
            if (response.data) {
                this.setState({
                    inavailable_dates: FormatManager.datesArray(response.data.inavailableDates)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    onSubmit = (event) => {
        const id = toast.loading('Création de la période bloquée...', { toastId: 'create-locked-period' });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/events/create-locked',
            data: {
                start: FormatManager.dateDBFormat(event.start),
                end: FormatManager.dateDBFormat(event.end)
            }
        }).then((response) => {
            if (response.data) {
                toast.update(id, {
                    render: 'Période bloquée créée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de créer la période bloquée',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    showStartMonth = () => {
        if (!this.state.start) return;
        if (moment(this.state.end).diff(moment(this.state.start), 'months') < 1) this.endCreate.current.getDayPicker().showMonth(this.state.start);
    }
    
    handleStartChange = (start) => {
        this.setState({ start: start });
    }
    
    handleEndChange = (end) => {
        this.setState({ end: end }, this.showStartMonth);
    }

    render() {
        const { start, end } = this.state;
        const modifiers = { start: start, end: end };
        let today = new Date();

        return (
            <div className="CreateLockedEvent">
                <Formik
                    initialValues={{ 
                        start: this.state.start ?? "",
                        end: this.state.end ?? ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            start: Yup.date()
                                .transform(FormatManager.parseDateString)
                                .typeError("Veuillez saisir une date")
                                .required("Date de début requise"),
                            end: Yup.date("Veuillez saisir une date")
                                .transform(FormatManager.parseDateString)
                                .typeError("Veuillez saisir une date")
                                .required("Date de fin requise")
                        })
                    }
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched }) => (
                        <Form className="create-form" autoComplete="off">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Date de début*</label>
                                    <div className={touched.start && errors.start ? "field error" : "field"}>
                                        <Icon fontSize="small">today</Icon>
                                        <DatePickerField
                                            name="start"
                                            value={start}
                                            dayPickerProps={{ 
                                                selectedDays: [start, { from: start, to: end }],
                                                disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: today, after: end }) : [],
                                                month: today,
                                                fromMonth: today,
                                                toMonth: end,
                                                modifiers: modifiers,
                                                onDayClick: () => this.endCreate.current.getInput().focus(),
                                                className: "dates-range"
                                            }}
                                            onDayChange={this.handleStartChange}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="start" />
                                </div>
                                <div className="form-group">
                                    <label>Date de fin*</label>
                                    <div className={touched.end && errors.end ? "field error" : "field"}>
                                        <Icon fontSize="small">today</Icon>
                                        <DatePickerField
                                            name="end"
                                            ref={this.endCreate}
                                            value={end}
                                            dayPickerProps={{ 
                                                selectedDays: [start, { from: start, to: end }],
                                                disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: start || today }) : [],
                                                modifiers: modifiers,
                                                month: start || today,
                                                fromMonth: start || today,
                                                className: "dates-range"
                                            }}
                                            onDayChange={this.handleEndChange}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="end" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">save</Icon><span>Enregistrer</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default CreateLockedEvent;