import React from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';

import Table from '../table/Table';
import Confirmation from '../confirmation/Confirmation';
import Overlay from '../overlay/Overlay';
import EditBooking from './edit_booking/EditBooking';
import CreateBooking from './create_booking/CreateBooking';
import NotFound from '../../not_found/NotFound';
import JWTManager from '../../../utils/JWTManager';
import FormatManager from '../../../utils/FormatManager';
import './Bookings.scss';

class Bookings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            not_validated: null,
            upcomings: null,
            all_bookings: null,
            show_validate: false,
            booking_validate_id: null,
            show_cancel: false,
            booking_cancel_id: null,
            show_delete: false,
            booking_delete_id: null,
            show_edit: false,
            show_create: false
        }
    }

    componentDidMount() {
        this.checkPath();
        setTimeout(() => this.setState({ hidden: false }), 1);
        if (JWTManager.getToken() !== null) this.loadData();
        animateScroll.scrollTo(0, {
            duration: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) this.checkPath();
    }

    checkPath = () => {
        this.setState({ 
            show_validate: this.props.location.pathname.includes('/validate'),
            show_cancel: this.props.location.pathname.includes('/cancel'),
            show_delete: this.props.location.pathname.includes('/delete'),
            show_edit: this.props.location.pathname.includes('/edit'),
            show_create: this.props.location.pathname.includes('/create')
        });
        this.props.onFullscreenChange(
            this.props.location.pathname.includes('/validate') ||
            this.props.location.pathname.includes('/cancel') ||
            this.props.location.pathname.includes('/delete') ||
            this.props.location.pathname.includes('/edit') ||
            this.props.location.pathname.includes('/create')
        );
    }

    loadData = () => {
        // Récupération des réservations en attente
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/bookings/not-validated'
        }).then((response) => {
            if (!response) {
                this.setState({
                    not_validated: null
                });
            }
            if (response.data) {
                this.setState({
                    not_validated: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });

        // Récupération des réservations à venir
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/bookings/upcomings'
        }).then((response) => {
            if (!response) {
                this.setState({
                    upcomings: null
                });
            }
            if (response.data) {
                this.setState({
                    upcomings: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });

        // Récupération de toutes réservations
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/bookings'
        }).then((response) => {
            if (!response) {
                this.setState({
                    all_bookings: null
                });
            }
            if (response.data) {
                this.setState({
                    all_bookings: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getColumns = () => {
        return [
            {
                Header: 'Locataire',
                accessor: 'name',
                className: 'main',
                Cell: tableProps => tableProps.row.original.name.toUpperCase() + ' ' + tableProps.row.original.firstname
            }, {
                Header: 'Date d\'entrée',
                accessor: 'in_date',
                className: 'main',
                Cell: tableProps => FormatManager.dateLocaleFormat(tableProps.row.original.in_date)
            }, {
                Header: 'Date de sortie',
                accessor: 'out_date',
                className: 'main',
                Cell: tableProps => FormatManager.dateLocaleFormat(tableProps.row.original.out_date)
            }, {
                Header: 'Nombre de personne',
                id: 'number',
                className: 'center',
                disableSortBy: true,
                Cell: tableProps => <>
                    <div className="number-of">
                        <Icon fontSize="tiny">face</Icon>
                        <span>{tableProps.row.original.nb_adults} {tableProps.row.original.nb_adults < 2 ? 'adulte' : 'adultes'}</span>
                    </div>
                    <div className="number-of">
                        <Icon fontSize="tiny">child_care</Icon>
                        <span>{tableProps.row.original.nb_childs} {tableProps.row.original.nb_childs < 2 ? 'enfant' : 'enfants'}</span>
                    </div>
                </>
            }, {
                Header: 'Ménage',
                accessor: 'menage',
                className: 'center',
                sortType: (rowA, rowB, id, desc) => {
                    if (rowA.original[id] && !rowB.original[id]) return 1;
                    if (!rowA.original[id] && rowB.original[id]) return -1;
                    return 0;
                },
                Cell: tableProps => tableProps.row.original.menage ? <Icon fontSize="tiny">cleaning_services</Icon> : null
            }, {
                Header: 'Prix',
                accessor: 'price',
                Cell: tableProps => FormatManager.priceFormat(tableProps.row.original.price)
            }, {
                Header: '',
                id: 'edit-column',
                disableSortBy: true,
                className: 'actions',
                Cell: tableProps => <div className="action-buttons">
                    {
                        tableProps.row.original.validated
                        && <Link 
                            to={this.props.match.url + "/" + tableProps.row.original.id + "/cancel"} 
                            className={new Date(tableProps.row.original.in_date).getTime() < new Date().getTime() ? "cancel-button disabled" : "cancel-button"}
                        >
                            <Icon fontSize="tiny">event_busy</Icon><span>Annuler</span>
                        </Link>
                    }
                    {
                        !tableProps.row.original.validated
                        && <Link 
                            to={this.props.match.url + "/" + tableProps.row.original.id + "/validate"} 
                            className={new Date(tableProps.row.original.in_date).getTime() < new Date().getTime() ? "validate-button disabled" : "validate-button"}
                        >
                            <Icon fontSize="tiny">event_available</Icon><span>Valider</span>
                        </Link>
                    }
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/edit"} className="edit-button">
                        <Icon fontSize="tiny">edit</Icon><span>Modifier</span>
                    </Link>
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/delete"} className="delete-button">
                        <Icon fontSize="tiny">delete</Icon><span>Supprimer</span>
                    </Link>
                </div>
            }
        ];
    }

    onValidateOpen = (row) => {
        this.setState({
            show_validate: true,
            booking_validate_id: row
        });
        this.props.onFullscreenChange(true);
    }

    onValidateAnnulation = () => {
        this.setState({
            show_validate: false,
            booking_validate_id: null
        });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onValidateConfirmation = () => {
        // Valide la réservation
        const id = toast.loading('Validation de la réservation...', { toastId: 'validate-booking-' + this.state.booking_validate_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/bookings/' + this.state.booking_validate_id + '/validate',
        }).then((response) => {
            if (response.data) {
                this.setState({
                    show_validate: false,
                    booking_validate_id: null
                });
                this.props.onFullscreenChange(false);
                toast.update(id, {
                    render: 'Réservation validée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
                setTimeout(() => this.props.history.push(this.props.match.url), 400);
            }
        }).catch((error) => {
            if (error.response.data) {
                toast.update(id, {
                    render: error.response.data.message,
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de valider la réservation',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    onCancelOpen = (row) => {
        this.setState({
            show_cancel: true,
            booking_cancel_id: row
        });
        this.props.onFullscreenChange(true);
    }

    onCancelAnnulation = () => {
        this.setState({
            show_cancel: false,
            booking_cancel_id: null
        });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCancelConfirmation = () => {
        // Annule la réservation
        const id = toast.loading('Annulation de la réservation...', { toastId: 'cancel-booking-' + this.state.booking_cancel_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/bookings/' + this.state.booking_cancel_id + '/cancel',
        }).then((response) => {
            if (response.data) {
                this.setState({
                    show_cancel: false,
                    booking_cancel_id: null
                });
                this.props.onFullscreenChange(false);
                toast.update(id, {
                    render: 'Réservation annulée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
                setTimeout(() => this.props.history.push(this.props.match.url), 400);
            }
        }).catch((error) => {
            if (error.response.data) {
                toast.update(id, {
                    render: error.response.data.message,
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible d\'annuler la réservation',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    onDeleteOpen = (row) => {
        this.setState({
            show_delete: true,
            booking_delete_id: row
        });
        this.props.onFullscreenChange(true);
    }

    onDeleteAnnulation = () => {
        this.setState({
            show_delete: false,
            booking_delete_id: null
        });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onDeleteConfirmation = () => {
        // Suppression de l'image
        const id = toast.loading('Suppression de la réservation...', { toastId: 'delete-booking-' + this.state.booking_delete_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/auth/bookings/' + this.state.booking_delete_id,
        }).then((response) => {
            if (response.data.success) {
                this.setState({
                    show_delete: false,
                    booking_delete_id: null
                });
                this.props.onFullscreenChange(false);
                toast.update(id, {
                    render: 'Réservation supprimée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
                setTimeout(() => this.props.history.push(this.props.match.url), 400);
            }
        }).catch((error) => {
            console.error(error.response);
            this.props.onFullscreenChange(false);
            toast.update(id, {
                render: 'Impossible de supprimer la réservation',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000
            });
        });
    }

    onEditClose = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onEditSubmit = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateClose = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateSubmit = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    render() {
        return (
            <div className={this.state.hidden ? "Bookings hidden" : "Bookings"}>
                <h2 className={this.state.hidden ? "hidden" : ""}>Réservations</h2>
                <div className="bookings-content">
                    <div className="bookings-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">pending_actions</Icon><span>Réservations en attente</span></h3>
                        </div>
                        <div className="block-content">
                            {
                                (this.state.not_validated === null || this.state.not_validated.length === 0)
                                && <p>Aucune réservation en attente</p>
                            }
                            {
                                this.state.not_validated && this.state.not_validated.length > 0
                                && <Table data={this.state.not_validated} columns={this.getColumns()} pageSize={5} />
                            }
                        </div>
                    </div>
                </div>
                <div className="bookings-content">
                    <div className="bookings-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">upcoming</Icon><span>Réservations à venir</span></h3>
                        </div>
                        <div className="block-content">
                            {
                                (this.state.upcomings === null || this.state.upcomings.length === 0)
                                && <p>Aucune réservation à venir</p>
                            }
                            {
                                this.state.upcomings && this.state.upcomings.length > 0
                                && <Table data={this.state.upcomings} columns={this.getColumns()} pageSize={5} />
                            }
                        </div>
                    </div>
                </div>
                <div className="bookings-content">
                    <div className="bookings-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">confirmation_number</Icon><span>Toutes les réservations</span></h3>
                            <Link to={this.props.match.url + "/create"} className="create-button">
                                <Icon fontSize="small">person_add</Icon><span>Ajouter une réservation</span>
                            </Link>
                        </div>
                        <div className="block-content">
                            {
                                (this.state.all_bookings === null || this.state.all_bookings.length === 0)
                                && <p>Aucune réservation</p>
                            }
                            {
                                this.state.all_bookings && this.state.all_bookings.length > 0
                                && <Table data={this.state.all_bookings} columns={this.getColumns()} pageSize={10} />
                            }
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route path={this.props.match.path + "/:bookingId/edit"} exact>
                        <Overlay
                            title="Modification de la réservation"
                            onClose={this.onEditClose}
                            show_overlay={this.state.show_edit}
                        >
                            <EditBooking
                                onSubmit={this.onEditSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/:elementId/validate"} exact>
                        <Confirmation
                            title="Voulez-vous valider cette réservation ?"
                            positive={true}
                            onOpen={this.onValidateOpen}
                            onCancel={this.onValidateAnnulation}
                            onConfirm={this.onValidateConfirmation}
                            show_confirmation={this.state.show_validate}
                        />
                    </Route>
                    <Route path={this.props.match.path + "/:elementId/cancel"} exact>
                        <Confirmation
                            title="Voulez-vous annuler cette réservation ?"
                            onOpen={this.onCancelOpen}
                            onCancel={this.onCancelAnnulation}
                            onConfirm={this.onCancelConfirmation}
                            show_confirmation={this.state.show_cancel}
                        />
                    </Route>
                    <Route path={this.props.match.path + "/:elementId/delete"} exact>
                        <Confirmation
                            title="Voulez-vous supprimer cette réservation ?"
                            onOpen={this.onDeleteOpen}
                            onCancel={this.onDeleteAnnulation}
                            onConfirm={this.onDeleteConfirmation}
                            show_confirmation={this.state.show_delete}
                        />
                    </Route>
                    <Route path={this.props.match.path + "/create"} exact>
                        <Overlay 
                            title="Nouvelle réservation"
                            onClose={this.onCreateClose}
                            show_overlay={this.state.show_create}
                        >
                            <CreateBooking 
                                onSubmit={this.onCreateSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/*"}>
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Bookings);