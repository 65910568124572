import React from 'react';
import { useField, useFormikContext } from 'formik';
import Select from 'react-select';

const SelectField = ({ ...props }) => {
    const { setFieldValue } = useFormikContext();
    const [field] = useField(props);
    return (
        <Select
            {...field}
            {...props}
            isOptionSelected={option => field.value === option.value }
            className={props.className ? props.className + " select-container" : "select-container"}
            classNamePrefix="select"
            onChange={(option) => setFieldValue(field.name, option)}
        />
    );
};

export default SelectField;