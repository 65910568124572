import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import Icon from '@mui/material/Icon';

import './Confirmation.scss';

class Confirmation extends React.Component {
    constructor(props) {
        super(props);

        this.confirmationBlockRef = createRef();
    }

    componentDidMount() {
        if (this.props.onOpen) this.props.onOpen(this.props.match.params.elementId);
        document.addEventListener('mousedown', this.onClickOutside);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutside);
    }

    onClickOutside = (e) => {
        if (this.props.show_confirmation && this.confirmationBlockRef.current && ! this.confirmationBlockRef.current.contains(e.target)) this.props.onCancel(e);
    }

    getClassName = () => {
        return this.props.show_confirmation ? "Confirmation" : "Confirmation hidden";
    }

    render() {
        return (
            <div className={this.getClassName()}>
                <div className="confirmation-block" ref={this.confirmationBlockRef}>
                    <h4>{this.props.title}</h4>
                    <div className="buttons-container">
                        <button onClick={(e) => this.props.onCancel(e)} className="cancel-button">
                            <Icon fontSize="tiny">cancel</Icon><span>Annuler</span>
                        </button>
                        <button onClick={(e) => this.props.onConfirm(e)} className={this.props.positive ? "confirm-button positive" : "confirm-button"}>
                            <Icon fontSize="tiny">check_circle</Icon><span>Confirmer</span>
                        </button>
                    </div>
                </div>
            </div>
        );
    }
}

export default withRouter(Confirmation);