import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import EditorField from '../../../../utils/editor_field/EditorField';
import SelectField from '../../../../utils/SelectField';
import FormatManager from '../../../../utils/FormatManager';
import JWTManager from '../../../../utils/JWTManager';
import IMAGES_POSITION from '../../../../constants/images_position.enum';
import './EditSection.scss';
import PAGES from '../../../../constants/pages.enum';

class EditSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            section: null,
            editor_state: FormatManager.htmlToEditor(),
            images: null,
            images_options: null,
            initial_options: null,
            error_response: null
        }
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null) {
            this.loadData();
            if (this.props.match.params.sectionId) this.loadSection();
        }
    }

    loadData = () => {
        // Récupération des images
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/images'
        }).then((response) => {
            if (!response) {
                this.setState({
                    images: null,
                    images_options: null
                });
            }
            if (response.data) {
                this.setState({
                    images: response.data,
                    images_options: this.getImagesOptions(response.data)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    loadSection = () => {
        // Récupération de la section
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/sections/' + this.props.match.params.sectionId
        }).then((response) => {
            if (!response) {
                this.setState({
                    section: null,
                    editor_state: FormatManager.htmlToEditor(),
                    initial_options: null
                });
            }
            if (response.data) {
                this.setState({
                    section: response.data,
                    editor_state: FormatManager.htmlToEditor(response.data.text),
                    initial_options: this.getInitialOptions(response.data.images)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(error.response.data.message);
        });
    }

    getInitialOptions = (images) => {
        if (this.state.images_options && images && images.length > 0) {
            const images_id = images.map((image) => image._id);
            const image1 = this.state.images_options.filter((option) => option.value === images_id[0])[0];
            if (images_id.length > 1) {
                const image2 = this.state.images_options.filter((option) => option.value === images_id[1])[0];
                return [image1, image2];
            }
            return [image1];
        }
        return null;
    }

    getImagesOptions = (images) => {
        return images.map(image => ({
            value: image.id,
            label: <div className='image-option'>
                <img 
                    srcSet={FormatManager.imageSrcSet(image.paths)}
                    sizes="6rem"
                    src={image.paths.w1920}
                    alt = ''     
                />
                <span>{image.name}</span>
            </div>
        }));
    }

    imagesFilter = (option, searchText) => {
        return option.data.label.props.children[1].props.children.toLowerCase().includes(searchText.toLowerCase());
    }

    onEditorStateChange = (editorState, setFieldValue) => {
        setFieldValue('editorState', editorState);
    }

    onSubmit = (section) => {
        const id = toast.loading('Mise à jour de la section...', { toastId: 'edit-section-' + this.state.section.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/sections/' + this.state.section.id,
            data: {
                text: FormatManager.editorToHtml(section.editorState),
                images: section.images ? section.images.map((image) => image.value) : [],
                images_position: section.images_position
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Section mise à jour avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de mettre à jour la section',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="EditSection">
                <Formik
                    initialValues={{ 
                        editorState: this.state.editor_state,
                        images: this.state.initial_options,
                        images_position: this.state.section ? this.state.section.images_position : IMAGES_POSITION.UNDER
                    }}
                    validationSchema={
                        Yup.object().shape({
                            editorState: Yup.object(),
                            images: Yup.mixed(),
                            images_position: Yup.string()
                        })
                    }
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="edit-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Texte</label>
                                    <div className={touched.editorState && errors.editorState ? "field editor error" : "field editor"}>
                                        <Icon fontSize="small">article</Icon>
                                        <EditorField
                                            editorState={values.editorState}
                                            disabledBlock={['header-one', 'header-two']}
                                            onChange={setFieldValue}
                                            onEditorStateChange={(nextEditorState) => this.onEditorStateChange(nextEditorState, setFieldValue)}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="editorState" />
                                </div>
                            </div>
                            {
                                this.state.section && this.state.section.page !== PAGES.LEGAL_NOTICE && this.state.section.page !== PAGES.TERMS_OF_SALES
                                && <>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Images</label>
                                            <div className={touched.images && errors.images ? "field error" : "field"}>
                                                <Icon fontSize="small">image</Icon>
                                                <SelectField
                                                    className="select-multi-image"
                                                    name="images"
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    options={
                                                        values.images && values.images.length > 1
                                                            ? values.images
                                                            : this.state.images_options
                                                    }
                                                    filterOption={this.imagesFilter}
                                                    placeholder="Choisir une image..."
                                                    noOptionsMessage={() => "Aucune image"}
                                                />
                                            </div>
                                            <ErrorMessage component="div" className="error" name="images" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group radio">
                                            <label>Position des images</label>
                                            <div className={touched.images_position && errors.images_position ? "field radio error" : "field radio"}>
                                                <Icon fontSize="small">picture_in_picture</Icon>
                                                <label>
                                                    <span className="radio-input">
                                                        <Field type="radio" name="images_position" value={IMAGES_POSITION.LEFT} />
                                                        <span className="radio-control"></span>
                                                    </span>
                                                    <span className="radio-label">Gauche</span>
                                                </label>
                                                <label>
                                                    <span className="radio-input">
                                                        <Field type="radio" name="images_position" value={IMAGES_POSITION.UNDER} />
                                                        <span className="radio-control"></span>
                                                    </span>
                                                    <span className="radio-label">En-dessous</span>
                                                </label>
                                                <label>
                                                    <span className="radio-input">
                                                        <Field type="radio" name="images_position" value={IMAGES_POSITION.RIGHT} />
                                                        <span className="radio-control"></span>
                                                    </span>
                                                    <span className="radio-label">Droite</span>
                                                </label>
                                            </div>
                                            <ErrorMessage component="div" className="error" name="images_position" />
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withRouter(EditSection);