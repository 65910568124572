import React, { createRef } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import FullCalendar from '@fullcalendar/react';
import dayGridPlugin from '@fullcalendar/daygrid';
import interactionPlugin from '@fullcalendar/interaction';
import frLocale from '@fullcalendar/core/locales/fr';
import Icon from '@mui/material/Icon';

import Overlay from '../overlay/Overlay';
import EditLockedEvent from './edit_locked_event/EditLockedEvent';
import CreateLockedEvent from './create_locked_event/CreateLockedEvent';
import NotFound from '../../not_found/NotFound';
import JWTManager from '../../../utils/JWTManager';
import EVENTS from '../../../constants/events.enum';
import './Calendar.scss';

class Calendar extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            airbnb: null,
            abritel: null,
            booking: null,
            locked: null,
            background: null,
            show_edit: false,
            show_create: false,
            create_start: null,
            create_end: null
        };

        this.calendar = createRef();
    }

    componentDidMount() {
        this.checkPath();
        setTimeout(() => this.setState({ hidden: false }), 1);
        if (JWTManager.getToken() !== null) this.loadData();
        animateScroll.scrollTo(0, {
            duration: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) this.checkPath();
    }

    checkPath = () => {
        if (this.props.location.pathname.includes('/create')) {
            this.setState({
                show_edit: false,
                show_create: true
            });
            this.props.onFullscreenChange(true);
        } else if (this.props.location.pathname.includes('/edit')) {
            this.setState({
                show_edit: true,
                show_create: false
            });
            this.props.onFullscreenChange(true);
        } else {
            this.setState({
                show_edit: false,
                show_create: false
            });
            this.props.onFullscreenChange(false);
        }
    }

    loadData = () => {
        // Récupération des événements
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/events/by-type'
        }).then((response) => {
            if (!response) {
                this.setState({
                    airbnb: null,
                    abritel: null,
                    booking: null,
                    locked: null,
                    background: null
                });
            }
            if (response.data) {
                this.setState({
                    airbnb: response.data.airbnb,
                    abritel: response.data.abritel,
                    booking: response.data.booking,
                    locked: response.data.locked,
                    background: response.data.background
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getEventContent = (eventInfo) => {
        return (
            <div className="fc-event-main">
                <div className="fc-event-title-container">
                    <div className="fc-event-title">
                        <span>{eventInfo.event.title}</span>
                        {
                            eventInfo.event.extendedProps.type === EVENTS.BOOKING && eventInfo.event.extendedProps.booking.menage
                            && <Icon fontSize="tiny">cleaning_services</Icon>
                        }
                    </div>
                </div>
            </div>
        );
    }

    onEventClick = (info) => {
        if (info.event.extendedProps) {
            // Redirection vers la réservation
            if (info.event.extendedProps.type === EVENTS.BOOKING && info.event.extendedProps.booking) this.props.history.push('/reservations/' + info.event.extendedProps.booking._id + '/edit')
            // Redirection vers l'événement verrouillé
            if (info.event.extendedProps.type === EVENTS.LOCKED) this.props.history.push(this.props.match.url + '/' + info.event.id + '/edit')
        }
    }

    onEditClose = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onEditSubmitOrDelete = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onDateClick = (info) => {
        let date = new Date(info.dateStr);
        if (!this.state.background.some(event => new Date(event.start).getTime() === date.getTime()) 
            && new Date().getTime() < date.getTime()) {
            this.setState({
                create_start: date,
                create_end: null
            }, () => this.props.history.push(this.props.match.url + '/create'));
        }
    }

    onSelect = (info) => {
        let start = new Date(info.startStr);
        let end = new Date(info.endStr);
        end.setDate(end.getDate() - 1);
        if (start.getTime() < end.getTime() && new Date().getTime() < start.getTime()) {
            this.setState({
                create_start: start,
                create_end: end
            }, () => this.props.history.push(this.props.match.url + '/create'));
        }
    }

    onCreateClose = () => {
        this.setState({ 
            show_create: false,
            create_start: null,
            create_end: null
        });
        this.props.onFullscreenChange(false);
        this.calendar.current.getApi().unselect();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateSubmit = () => {
        this.setState({ 
            show_create: false,
            create_start: null,
            create_end: null
        });
        this.props.onFullscreenChange(false);
        this.calendar.current.getApi().unselect();
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    render() {
        return (
            <div className={this.state.hidden ? "Calendar hidden" : "Calendar"}>
                <h2 className={this.state.hidden ? "hidden" : ""}>Calendrier</h2>
                <div className="calendar-content">
                    <div className="cal">
                        <div className="block-content">
                            <FullCalendar
                                plugins={[ dayGridPlugin, interactionPlugin ]}
                                initialView="dayGridMonth"
                                locales={[ frLocale ]}
                                locale="fr"
                                ref={this.calendar}
                                selectable={true}
                                selectOverlap={(event) => event.display !== 'background'}
                                height="56rem"
                                weekNumbers={true}
                                eventSources={[{
                                    events: this.state.airbnb,
                                    className: "airbnb-event"
                                }, {
                                    events: this.state.abritel,
                                    className: "abritel-event"
                                }, {
                                    events: this.state.booking,
                                    className: "booking-event"
                                }, {
                                    events: this.state.locked,
                                    className: "locked-event"
                                }, {
                                    events: this.state.background,
                                    className: "background-event"
                                }]}
                                eventContent={this.getEventContent}
                                eventClick={(info) => this.onEventClick(info)}
                                dateClick={(info) => this.onDateClick(info)}
                                select={(info) => this.onSelect(info)}
                                unselectAuto={false}
                                progressiveEventRendering={true}
                            />
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route path={this.props.match.path + "/:eventId/edit"} exact>
                        <Overlay
                            title="Modification de la période bloquée"
                            onClose={this.onEditClose}
                            show_overlay={this.state.show_edit}
                        >
                            <EditLockedEvent
                                onSubmit={this.onEditSubmitOrDelete}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/create"} exact>
                        <Overlay 
                            title="Nouvelle période bloquée"
                            onClose={this.onCreateClose}
                            show_overlay={this.state.show_create}
                        >
                            <CreateLockedEvent
                                create_start={this.state.create_start}
                                create_end={this.state.create_end}
                                onSubmit={this.onCreateSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/*"}>
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Calendar);