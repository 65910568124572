import React from 'react';
import { Field, ErrorMessage, useFormikContext } from 'formik';
import Icon from '@mui/material/Icon';

class Thumb extends React.Component {
    state = {
        file: undefined,
        thumb: undefined,
    };
  
    componentDidUpdate(prevProps) {
        if (this.props.file 
            && ((prevProps.file && prevProps.file.name !== this.props.file.name) || !prevProps.file)
        ) {
            let reader = new FileReader();
            reader.readAsDataURL(this.props.file);
            reader.onload = () => {
                this.setState({ 
                    file: this.props.file,
                    thumb: reader.result
                });
            };
    
        }
        if (!this.props.file && prevProps.file) {
            this.setState({
                file: undefined,
                thumb: undefined
            });
        }
    }
  
    render() {
        if (!this.state.file) { return null; }
        return (
            <img src={this.state.thumb} alt={this.state.file.name} />
        );
    }
}

const ImageUploadField = ({ ...props }) => {
    const { values, setFieldValue } = useFormikContext();
    return (
        <div className="image-upload">
            <label htmlFor="file">
                <div className="label" style={values.image ? {display: "none"} : {display: "flex"}}>
                    <Icon fontSize="small">add_photo_alternate</Icon>
                    <span>Choisir une image</span>
                </div>
                <Thumb file={values.image} />
            </label>
            <Field
                {...props}
                type="file"
                name="file"
                id="file"
                accept="image/jpeg, image/png"
                onChange={(event) => setFieldValue("image", event.currentTarget.files[0])}
            />
            <ErrorMessage component="div" className="error" name="image" />
        </div>
    );
};

export default ImageUploadField;