import React from 'react';
import { withRouter } from 'react-router-dom';
import { formatPhoneNumberIntl } from 'react-phone-number-input';
import Icon from '@mui/material/Icon';

import FormatManager from '../../../../utils/FormatManager';
import EVENTS from '../../../../constants/events.enum';
import './EventBlock.scss';

class EventBlock extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true
        }
    }

    componentDidMount() {
        setTimeout(() => this.setState({ hidden: false }), 1);
    }

    getClassName = () => {
        if (this.props.type) return 'EventBlock ' + this.props.type; 
        return this.props.event.type === EVENTS.AIRBNB ? 'EventBlock airbnb'
            : this.props.event.type === EVENTS.ABRITEL ? 'EventBlock abritel'
            : this.props.event.type === EVENTS.BOOKING ? 'EventBlock booking'
            : this.props.event.type === EVENTS.LOCKED ? 'EventBlock locked'
            : 'EventBlock';
    }

    getType = () => {
        return this.props.event.type === EVENTS.AIRBNB ? 'Airbnb'
            : this.props.event.type === EVENTS.ABRITEL ? 'Abritel'
            : this.props.event.type === EVENTS.BOOKING ? 'Réservation'
            : this.props.event.type === EVENTS.LOCKED ? 'Verrouillé'
            : '';
    }

    onEventClick = (event) => {
        if (event.target.localName !== 'a') {
            event.preventDefault();
            if (this.props.type && this.props.type === 'not-validated') this.props.history.push('/reservations/' + this.props.event.id + '/edit');
            if (this.props.type && this.props.type === 'without-comment') this.props.history.push('/avis/' + this.props.event.id + '/create_comment');
            if (this.props.event.type === EVENTS.BOOKING) this.props.history.push('/reservations/' + this.props.event.booking._id + '/edit');
            if (this.props.event.type === EVENTS.LOCKED) this.props.history.push('/calendrier/' + this.props.event.id + '/edit');
        }
    }

    render() {
        return (
            <div className={this.state.hidden ? this.getClassName() + " hidden" : this.getClassName()} onClick={(e) => this.onEventClick(e)}>
                {
                    !this.props.type
                    && <>
                        <h4>{this.getType()}</h4>
                        <div className="event-info">
                            <div className="date">
                                <Icon fontSize="small">assignment_turned_in</Icon>
                                <span>{FormatManager.dateStringFormat(this.props.event.start)}</span>
                            </div>
                            <div className="date">
                                <Icon fontSize="small">assignment_return</Icon>
                                <span>{FormatManager.dateStringFormat(this.props.event.end)}</span>
                            </div>
                            {
                                this.props.event.booking
                                && <>
                                    <div className="customer">
                                        <Icon fontSize="small">assignment_ind</Icon>
                                        <span>{this.props.event.booking.name} {this.props.event.booking.firstname}</span>
                                    </div>
                                    <div className="customer">
                                        <Icon fontSize="small">email</Icon>
                                        <span className="email"><a href={"mailto:" + this.props.event.booking.email} title="Envoyer un mail">{this.props.event.booking.email}</a></span>
                                    </div>
                                    <div className="customer">
                                        <Icon fontSize="small">phone</Icon>
                                        <span><a href={"tel:" + this.props.event.booking.phone} title="Téléphoner">{formatPhoneNumberIntl(this.props.event.booking.phone)}</a></span>
                                    </div>
                                    <div className="customer">
                                        <Icon fontSize="small">euro</Icon>
                                        <span>{FormatManager.priceFormat(this.props.event.booking.price)}</span>
                                    </div>
                                    <div className="customer">
                                        <Icon fontSize="small">face</Icon>
                                        <span>{this.props.event.booking.nb_adults} {this.props.event.booking.nb_adults < 2 ? 'adulte' : 'adultes'}</span>
                                    </div>
                                    <div className="customer">
                                        <Icon fontSize="small">child_care</Icon>
                                        <span>{this.props.event.booking.nb_childs} {this.props.event.booking.nb_childs < 2 ? 'enfant' : 'enfants'}</span>
                                    </div>
                                    <div className="customer">
                                        <Icon fontSize="small">cleaning_services</Icon>
                                        <span>Option ménage : {this.props.event.booking.menage ? 'Oui' : 'Non'}</span>
                                    </div>
                                </>
                            }
                        </div>
                    </>
                }
                {
                    this.props.type && this.props.type === "not-validated"
                    && <>
                        <h4>{this.getType()}</h4>
                        <div className="event-info">
                            <div className="date">
                                <Icon fontSize="small">assignment_turned_in</Icon>
                                <span>{FormatManager.dateStringFormat(this.props.event.in_date)}</span>
                            </div>
                            <div className="date">
                                <Icon fontSize="small">assignment_return</Icon>
                                <span>{FormatManager.dateStringFormat(this.props.event.out_date)}</span>
                            </div>
                            <div className="customer">
                                <Icon fontSize="small">assignment_ind</Icon>
                                <span>{this.props.event.name} {this.props.event.firstname}</span>
                            </div>
                            <div className="customer">
                                <Icon fontSize="small">email</Icon>
                                <span className="email"><a href={"mailto:" + this.props.event.email} title="Envoyer un mail">{this.props.event.email}</a></span>
                            </div>
                            <div className="customer">
                                <Icon fontSize="small">phone</Icon>
                                <span><a href={"tel:" + this.props.event.phone} title="Téléphoner">{formatPhoneNumberIntl(this.props.event.phone)}</a></span>
                            </div>
                            <div className="customer">
                                <Icon fontSize="small">euro</Icon>
                                <span>{FormatManager.priceFormat(this.props.event.price)}</span>
                            </div>
                            <div className="customer">
                                <Icon fontSize="small">face</Icon>
                                <span>{this.props.event.nb_adults} {this.props.event.nb_adults < 2 ? 'adulte' : 'adultes'}</span>
                            </div>
                            <div className="customer">
                                <Icon fontSize="small">child_care</Icon>
                                <span>{this.props.event.nb_childs} {this.props.event.nb_childs < 2 ? 'enfant' : 'enfants'}</span>
                            </div>
                            <div className="customer">
                                <Icon fontSize="small">cleaning_services</Icon>
                                <span>Option ménage : {this.props.event.menage ? 'Oui' : 'Non'}</span>
                            </div>
                        </div>
                    </>
                }
                {
                    this.props.type && this.props.type === "without-comment"
                    && <>
                        <h4>{this.getType()}</h4>
                        <div className="event-info">
                            <div className="customer">
                                <Icon fontSize="small">face</Icon>
                                <span>{this.props.event.name} {this.props.event.firstname}</span>
                            </div>
                            <div className="customer">
                                <div className="stars-container">
                                    {
                                        this.props.event.note >= 1 
                                            ? <Icon fontSize="small">star_rate</Icon>
                                            : this.props.event.note === 0.5
                                                ? <Icon fontSize="small">star_half</Icon>
                                                : <Icon fontSize="small">star_border</Icon>
                                    }
                                    {
                                        this.props.event.note >= 2
                                            ? <Icon fontSize="small">star_rate</Icon>
                                            : this.props.event.note === 1.5
                                                ? <Icon fontSize="small">star_half</Icon>
                                                : <Icon fontSize="small">star_border</Icon>
                                    }
                                    {
                                        this.props.event.note >= 3 
                                            ? <Icon fontSize="small">star_rate</Icon>
                                            : this.props.event.note === 2.5
                                                ? <Icon fontSize="small">star_half</Icon>
                                                : <Icon fontSize="small">star_border</Icon>
                                    }
                                    {
                                        this.props.event.note >= 4
                                            ? <Icon fontSize="small">star_rate</Icon>
                                            : this.props.event.note === 3.5
                                                ? <Icon fontSize="small">star_half</Icon>
                                                : <Icon fontSize="small">star_border</Icon>
                                    }
                                    {
                                        this.props.event.note >= 5
                                            ? <Icon fontSize="small">star_rate</Icon>
                                            : this.props.event.note === 4.5
                                                ? <Icon fontSize="small">star_half</Icon>
                                                : <Icon fontSize="small">star_border</Icon>
                                    }
                                </div>
                            </div>
                            <div className="customer">
                                <Icon fontSize="small">rate_review</Icon>
                                <span>{this.props.event.review && this.props.event.review.replace(/<[^>]*>?/gm, '')}</span>
                            </div>
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default withRouter(EventBlock);