import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import FormatManager  from '../../../../utils/FormatManager';
import JWTManager from '../../../../utils/JWTManager';
import './EditImage.scss';

class EditImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            image: null,
            error_response: null
        }
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null && this.props.match.params.imageId) this.loadData();
    }

    loadData = () => {
        // Récupération de l'image
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/images/' + this.props.match.params.imageId
        }).then((response) => {
            if (!response) {
                this.setState({
                    image: null
                });
            }
            if (response.data) {
                this.setState({
                    image: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(error.response.data.message);
        });
    }

    onSubmit = (image) => {
        // Mise à jour de l'image
        const id = toast.loading('Mise à jour de l\'image...', { toastId: 'edit-image-' + this.state.image.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/images/' + this.state.image.id,
            data: image
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Image modifiée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de modifier l\'image',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="EditImage">
                <Formik
                    initialValues={{ 
                        name: this.state.image ? this.state.image.name : "",
                        desc: this.state.image ? this.state.image.desc : ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            name: Yup.string()
                                .required("Nom de l'image requis"),
                            desc: Yup.string()
                        })
                    }
                    enableReinitialize={true}
                    onSubmit={(image) => this.onSubmit(image)}
                >
                    {({ errors, touched }) => (
                        <Form className="edit-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <img 
                                srcSet={this.state.image && FormatManager.imageSrcSet(this.state.image.paths)} 
                                sizes={this.state.image && (Math.round(256 * this.state.image.aspect_ratio * 10) / 10 + "px")}
                                src={this.state.image && process.env.REACT_APP_API_URL + this.state.image.paths.w1920} 
                                alt={this.state.image && this.state.image.name}
                            />
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Nom de l'image*</label>
                                    <div className={touched.name && errors.name ? "field error" : "field"}>
                                        <Icon fontSize="small">image</Icon>
                                        <Field name="name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="name" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Description</label>
                                    <div className={touched.desc && errors.desc ? "field textarea error" : "field textarea"}>
                                        <Icon fontSize="small">article</Icon>
                                        <Field name="desc" as="textarea" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="desc" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withRouter(EditImage);