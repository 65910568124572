import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import EditorField from '../../../../utils/editor_field/EditorField';
import FormatManager from '../../../../utils/FormatManager';
import JWTManager from '../../../../utils/JWTManager';
import TEXT_POSITION from '../../../../constants/text_position.enum';
import './EditSlide.scss';

class EditSlide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            slide: null,
            editor_state: FormatManager.htmlToEditor(),
            error_response: null
        }
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null && this.props.match.params.slideId) this.loadSlide();
    }

    loadSlide = () => {
        // Récupération de la diapositive
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/slideshow/' + this.props.match.params.slideId
        }).then((response) => {
            if (!response) {
                this.setState({
                    slide: null,
                    editor_state: FormatManager.htmlToEditor()
                });
            }
            if (response.data) {
                this.setState({
                    slide: response.data,
                    editor_state: FormatManager.htmlToEditor(response.data.text)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(error.response.data.message);
        });
    }

    onEditorStateChange = (editorState, setFieldValue) => {
        setFieldValue('editorState', editorState);
    }

    onSubmit = (slide) => {
        const id = toast.loading('Mise à jour de la diapositive...', { toastId: 'edit-slide-' + this.state.slide.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/slideshow/' + this.state.slide.id,
            data: {
                text: FormatManager.editorToHtml(slide.editorState),
                text_position: slide.text_position
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Diapositive mise à jour avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de mettre à jour la diapositive',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="EditSlide">
                <Formik
                    initialValues={{
                        editorState: this.state.editor_state,
                        text_position: this.state.slide ? this.state.slide.text_position : TEXT_POSITION.BOTTOM_LEFT
                    }}
                    validationSchema={
                        Yup.object().shape({
                            editorState: Yup.object(),
                            text_position: Yup.string()
                        })
                    }
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="edit-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <img 
                                srcSet={this.state.slide && FormatManager.imageSrcSet(this.state.slide.image.paths)} 
                                sizes={this.state.slide && (Math.round(256 * this.state.slide.image.aspect_ratio * 10) / 10 + "px")}
                                src={this.state.slide && process.env.REACT_APP_API_URL + this.state.slide.image.paths.w1920} 
                                alt={this.state.slide && this.state.slide.image.name}
                            />
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Texte</label>
                                    <div className={touched.editorState && errors.editorState ? "field editor error" : "field editor"}>
                                        <Icon fontSize="small">article</Icon>
                                        <EditorField
                                            editorState={values.editorState}
                                            disabledBlock={['header-one', 'header-two', 'unordered-list-item', 'ordered-list-item']}
                                            onChange={setFieldValue}
                                            onEditorStateChange={(nextEditorState) => this.onEditorStateChange(nextEditorState, setFieldValue)}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="editorState" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group radio">
                                    <label>Position du texte</label>
                                    <div className={touched.text_position && errors.text_position ? "field radio error" : "field radio"}>
                                        <Icon fontSize="small">picture_in_picture</Icon>
                                        <label>
                                            <span className="radio-input">
                                                <Field type="radio" name="text_position" value={TEXT_POSITION.TOP_LEFT} />
                                                <span className="radio-control"></span>
                                            </span>
                                            <span className="radio-label">En haut à gauche</span>
                                        </label>
                                        <label>
                                            <span className="radio-input">
                                                <Field type="radio" name="text_position" value={TEXT_POSITION.TOP_RIGHT} />
                                                <span className="radio-control"></span>
                                            </span>
                                            <span className="radio-label">En haut à droite</span>
                                        </label>
                                        <label>
                                            <span className="radio-input">
                                                <Field type="radio" name="text_position" value={TEXT_POSITION.BOTTOM_RIGHT} />
                                                <span className="radio-control"></span>
                                            </span>
                                            <span className="radio-label">En bas à droite</span>
                                        </label>
                                        <label>
                                            <span className="radio-input">
                                                <Field type="radio" name="text_position" value={TEXT_POSITION.BOTTOM_LEFT} />
                                                <span className="radio-control"></span>
                                            </span>
                                            <span className="radio-label">En bas à gauche</span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="text_position" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withRouter(EditSlide);