import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, ErrorMessage, Field } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import EditorField from '../../../../utils/editor_field/EditorField';
import SelectField from '../../../../utils/SelectField';
import FormatManager from '../../../../utils/FormatManager';
import JWTManager from '../../../../utils/JWTManager';
import IMAGES_POSITION from '../../../../constants/images_position.enum';
import PAGES from '../../../../constants/pages.enum';
import './CreateSection.scss';

class CreateSection extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editor_state: FormatManager.htmlToEditor(),
            images: null,
            images_options: null,
            error_response: null
        }
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null) this.loadData();
    }

    loadData = () => {
        // Récupération des images
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/images'
        }).then((response) => {
            if (!response) {
                this.setState({
                    images: null,
                    images_options: null
                });
            }
            if (response.data) {
                this.setState({
                    images: response.data,
                    images_options: this.getImagesOptions(response.data)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getImagesOptions = (images) => {
        return images.map(image => ({
            value: image.id,
            label: <div className='image-option'>
                <img 
                    srcSet={FormatManager.imageSrcSet(image.paths)}
                    sizes="6rem"
                    src={image.paths.w1920}
                    alt = ''     
                />
                <span>{image.name}</span>
            </div>
        }));
    }

    imagesFilter = (option, searchText) => {
        return option.data.label.props.children[1].props.children.toLowerCase().includes(searchText.toLowerCase());
    }

    onEditorStateChange = (editorState, setFieldValue) => {
        setFieldValue('editorState', editorState);
    }

    onSubmit = (section) => {
        const id = toast.loading('Création de la section...', { toastId: 'create-section' });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/sections/create',
            data: {
                page: this.props.page_type,
                text: FormatManager.editorToHtml(section.editorState),
                images: section.images ? section.images.map((image) => image.value) : [],
                images_position: section.images_position
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Section créée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de créer la section',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="CreateSection">
                <Formik
                    initialValues={{ 
                        editorState: this.state.editor_state,
                        images: [],
                        images_position: IMAGES_POSITION.UNDER
                    }}
                    validationSchema={
                        Yup.object().shape({
                            editorState: Yup.object(),
                            images: Yup.mixed(),
                            images_position: Yup.string()
                        })
                    }
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="create-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Texte</label>
                                    <div className={touched.editorState && errors.editorState ? "field editor error" : "field editor"}>
                                        <Icon fontSize="small">article</Icon>
                                        <EditorField
                                            editorState={values.editorState}
                                            disabledBlock={['header-one', 'header-two']}
                                            onChange={setFieldValue}
                                            onEditorStateChange={(nextEditorState) => this.onEditorStateChange(nextEditorState, setFieldValue)}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="editorState" />
                                </div>
                            </div>
                            {
                                this.props.page_type !== PAGES.LEGAL_NOTICE && this.props.page_type !== PAGES.TERMS_OF_SALES
                                && <>
                                    <div className="form-row">
                                        <div className="form-group">
                                            <label>Images</label>
                                            <div className={touched.images && errors.images ? "field error" : "field"}>
                                                <Icon fontSize="small">image</Icon>
                                                <SelectField
                                                    className="select-multi-image"
                                                    name="images"
                                                    isMulti
                                                    closeMenuOnSelect={false}
                                                    options={
                                                        values.images && values.images.length > 1
                                                            ? values.images
                                                            : this.state.images_options
                                                    }
                                                    filterOption={this.imagesFilter}
                                                    placeholder="Choisir une image..."
                                                    noOptionsMessage={() => "Aucune image"}
                                                />
                                            </div>
                                            <ErrorMessage component="div" className="error" name="images" />
                                        </div>
                                    </div>
                                    <div className="form-row">
                                        <div className="form-group radio">
                                            <label>Position des images</label>
                                            <div className={touched.images_position && errors.images_position ? "field radio error" : "field radio"}>
                                                <Icon fontSize="small">picture_in_picture</Icon>
                                                <label>
                                                    <span className="radio-input">
                                                        <Field type="radio" name="images_position" value={IMAGES_POSITION.LEFT} />
                                                        <span className="radio-control"></span>
                                                    </span>
                                                    <span className="radio-label">Gauche</span>
                                                </label>
                                                <label>
                                                    <span className="radio-input">
                                                        <Field type="radio" name="images_position" value={IMAGES_POSITION.UNDER} />
                                                        <span className="radio-control"></span>
                                                    </span>
                                                    <span className="radio-label">En-dessous</span>
                                                </label>
                                                <label>
                                                    <span className="radio-input">
                                                        <Field type="radio" name="images_position" value={IMAGES_POSITION.RIGHT} />
                                                        <span className="radio-control"></span>
                                                    </span>
                                                    <span className="radio-label">Droite</span>
                                                </label>
                                            </div>
                                            <ErrorMessage component="div" className="error" name="images_position" />
                                        </div>
                                    </div>
                                </>
                            }
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">add</Icon><span>Ajouter</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default CreateSection;