import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import axios from 'axios';
import Icon from '@mui/material/Icon';

import './Login.scss';

class Login extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            login_error: null
        }

        this.loginForm = createRef();
    }

    componentDidMount() {
        setTimeout(() => this.setState({ hidden: false }), 1);
        this.props.onFullscreenChange(true);
    }

    onSubmit = (user) => {
        axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/login',
            data: user
        }).then((response) => {
            if (response.data) {
                let result = this.props.onUserLogin(response.data.user, response.data.token, response.data.token_expires, response.data.refresh_token);
                if (result) {
                    this.setState({
                        login_error: null,
                        form_error: false
                    });
                    this.props.onFullscreenChange(false);
                    if (this.props.prevPath) {
                        this.props.history.push(this.props.prevPath);
                    } else {
                        this.props.history.push('/');
                    }
                }
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    login_error: error.response.data.message,
                    form_error: true
                });
            } else {
                console.error(error.response);
            }
        });
    }

    render() {
        return (
            <div className="Login">
                <div className={this.state.hidden ? "login-form hidden" : "login-form"}>
                    <img src="./logo/logo.svg" alt="Logo 'Au Refuge des Chamois'" />
                    <h2>Se connecter à l'administration</h2>
                    <Formik
                        initialValues={{ 
                            email: "",
                            password: ""
                        }}
                        validationSchema={
                            Yup.object().shape({
                                email: Yup.string()
                                    .email("Veuillez saisir une adresse mail")
                                    .required("Adresse mail requise"),
                                password: Yup.string()
                                    .required("Mot de passe requis")
                            })
                        }
                        innerRef={this.loginForm}
                        onSubmit={(user) => this.onSubmit(user)}
                    >
                        {({ errors, touched }) => (
                            <Form>
                                {
                                    this.state.login_error
                                    && <div className="form-error">{this.state.login_error}</div>
                                }
                                <div className="form-group">
                                    <label>Email</label>
                                    <div className={touched.email && errors.email ? "field error" : "field"} >
                                        <Icon fontSize="small">account_circle</Icon>
                                        <Field type="email" name="email" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="email" />
                                </div>
                                <div className="form-group">
                                    <label>Mot de passe</label>
                                    <div className={touched.password && errors.password ? "field error" : "field"}>
                                        <Icon fontSize="small">password</Icon>
                                        <Field type="password" name="password" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="password" />
                                </div>
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">login</Icon>
                                    <span>Connexion</span>
                                </button>
                            </Form>
                        )}
                    </Formik>
                </div>
            </div>
        );
    }
}

export default withRouter(Login);
