import React from 'react';
import { withRouter } from 'react-router-dom';
import { toast } from 'react-toastify';
import axios from 'axios';
import Icon from '@mui/material/Icon';

import FormatManager from '../../../../../utils/FormatManager';
import JWTManager from '../../../../../utils/JWTManager';
import NOTIFS from '../../../../../constants/notifs.enum';
import './NotificationBlock.scss';

class NotificationBlock extends React.Component {
    getClassName = () => {
        return this.props.notification.notif_type === NOTIFS.BOOKING ? 'NotificationBlock booking'
            : this.props.notification.notif_type === NOTIFS.REVIEW ? 'NotificationBlock review'
            : 'NotificationBlock';
    }

    onClick = (e) => {
        e.preventDefault();
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/notifications/mark-as-readed/' + this.props.notification.id
        }).then((response) => {
            if (response.data) {
                this.props.loadData();
                this.props.closeNotif();
                if (this.props.notification.notif_type === NOTIFS.BOOKING) this.props.history.push('/reservations/' + this.props.notification.notif._id + '/edit');
                if (this.props.notification.notif_type === NOTIFS.REVIEW) this.props.history.push('/avis/' + this.props.notification.notif._id + '/create_comment');
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    onDeleteClick = (e) => {
        e.preventDefault();
        e.stopPropagation();
        // Suppression de la notification
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/auth/notifications/' + this.props.notification.id
        }).then((response) => {
            if (response.data) {
                this.props.loadData();
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    render() {
        return (
            <div className={this.getClassName()} onClick={(e) => this.onClick(e)}>
                <button type="button" className="delete-button" onClick={(e) => this.onDeleteClick(e)} title="Supprimer la notification">
                    <Icon fontSize="tiny">close</Icon>
                </button>
                {
                    this.props.notification.notif_type === NOTIFS.BOOKING
                    && <>
                        <h5>Nouvelle demande de réservation</h5>
                        <div className="notif-info">
                            <div className="info">
                                <Icon fontSize="tiny">assignment_ind</Icon>
                                <span>{this.props.notification.notif.name} {this.props.notification.notif.firstname}</span>
                            </div>
                            <div className="info">
                                <Icon fontSize="tiny">assignment_turned_in</Icon>
                                <span>{FormatManager.dateStringFormat(this.props.notification.notif.in_date)}</span>
                            </div>
                            <div className="info">
                                <Icon fontSize="tiny">assignment_return</Icon>
                                <span>{FormatManager.dateStringFormat(this.props.notification.notif.out_date)}</span>
                            </div>
                        </div>
                    </>
                }
                {
                    this.props.notification.notif_type === NOTIFS.REVIEW
                    && <>
                        <h5>Nouvel avis</h5>
                        <div className="notif-info">
                            <div className="info">
                                <Icon fontSize="tiny">face</Icon>
                                <span>{this.props.notification.notif.name} {this.props.notification.notif.firstname}</span>
                            </div>
                            <div className="info">
                                <Icon fontSize="tiny">star_rate</Icon>
                                <span>{this.props.notification.notif.note}</span>
                            </div>
                            {
                                this.props.notification.notif.review && this.props.notification.notif.review.replace(/<[^>]*>?/gm, '').trim() !== ''
                                && <div className="info">
                                    <Icon fontSize="tiny">rate_review</Icon>
                                    <span>{this.props.notification.notif.review && this.props.notification.notif.review.replace(/<[^>]*>?/gm, '')}</span>
                                </div>
                            }
                        </div>
                    </>
                }
            </div>
        );
    }
}

export default withRouter(NotificationBlock);