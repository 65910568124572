import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';

import EventBlock from './event-block/EventBlock';
import JWTManager from '../../../utils/JWTManager';
import './Dashboard.scss';

class Dashboard extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            current_event: null,
            not_validated: null,
            upcomings_events: null,
            without_comment: null
        };
    }

    componentDidMount() {
        if (this.props.user !== null) this.loadData();
        setTimeout(() => this.setState({ hidden: false }), 1);
        animateScroll.scrollTo(0, {
            duration: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (this.props.user && this.props.user !== prevProps.user) this.loadData();
        if (this.state.hidden) setTimeout(() => this.setState({ hidden: false }), 1);
    }

    loadData = () => {
        // Récupération de l'événément en cours
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/events/current'
        }).then((response) => {
            if (!response) {
                this.setState({
                    current_event: null
                });
            }

            if (response.data) {
                this.setState({
                    current_event: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });

        // Récupération de l'événément en cours
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/bookings/not-validated'
        }).then((response) => {
            if (!response) {
                this.setState({
                    not_validated: null
                });
            }

            if (response.data) {
                this.setState({
                    not_validated: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });

        // Récupération des événements à venir
        axios({
            headers: {
                'Authorization' : JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/events/upcomings'
        }).then((response) => {
            if (!response) {
                this.setState({
                    upcomings_events: null
                });
            }

            if (response.data) {
                this.setState({
                    upcomings_events: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });

        // Récupération des avis en attente
        axios({
            headers: {
                'Authorization' : JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/reviews/without-comment'
        }).then((response) => {
            if (!response) {
                this.setState({
                    without_comment: null
                });
            }

            if (response.data) {
                this.setState({
                    without_comment: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    render() {
        return (
            <div className={this.state.hidden ? "Dashboard hidden" : "Dashboard"}>
                <h2 className={this.state.hidden ? "hidden" : ""}>Accueil</h2>
                <div className="dashboard-content">
                    <div className="packing-content">
                        <div className="block">
                            <div className="block-heading">
                                <h3><Icon fontSize="small">event_available</Icon><span>Réservation en cours</span></h3>
                            </div>
                            <div className="block-content">
                                {
                                    (
                                        this.state.current_event
                                        && <EventBlock event={this.state.current_event} />
                                    ) || <p>Aucune réservation en cours</p>
                                }
                            </div>
                        </div>
                        <div className="block">
                            <div className="block-heading">
                                <h3><Icon fontSize="small">pending_actions</Icon><span>Réservations en attente</span></h3>
                            </div>
                            <div className="block-content">
                                {
                                    (
                                        this.state.not_validated && this.state.not_validated.length > 0
                                        && this.state.not_validated.map((event, i) => <EventBlock event={event} type="not-validated" key={i} />)
                                    ) || <p>Aucune réservation en attente</p>
                                }
                            </div>
                        </div>
                    </div>
                    <div className="packing-content">
                        <div className="block">
                            <div className="block-heading">
                                <h3><Icon fontSize="small">question_answer</Icon><span>Avis en attente</span></h3>
                            </div>
                            <div className="block-content">
                                {
                                    (
                                        this.state.without_comment && this.state.without_comment.length > 0
                                        && this.state.without_comment.map((event, i) => <EventBlock event={event} type="without-comment" key={i} />)
                                    ) || <p>Aucun avis en attente</p>
                                }
                            </div>
                        </div>
                        <div className="block">
                            <div className="block-heading">
                                <h3><Icon fontSize="small">upcoming</Icon><span>Réservations à venir</span></h3>
                            </div>
                            <div className="block-content">
                                {
                                    (
                                        this.state.upcomings_events && this.state.upcomings_events.length > 0
                                        && this.state.upcomings_events.map((event, i) => <EventBlock event={event} key={i} />)
                                    ) || <p>Aucune réservation à venir</p>
                                }
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        )
    }
}

export default Dashboard;