import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import Icon from '@mui/material/Icon';

import NotificationBlock from './notification_block/NotificationBlock';
import JWTManager from '../../../../utils/JWTManager';
import './Notifications.scss';

class Notifications extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            unreaded: null,
            all: null,
            hidden_notifications: true,
            show_notifications: false,
            show_unreaded: true,
        }

        this.notificationBlockRef = createRef();
    }

    componentDidMount() {
        if (this.props.user) this.loadData();
        document.addEventListener('mousedown', this.onClickOutside);
    }

    componentDidUpdate(prevProps, prevState) {
        if (
            (this.props.user 
                && (!prevProps.user || (prevProps.user && prevProps.user._id !== this.props.user._id))
            ) || prevProps.location.pathname !== this.props.location.pathname
        ) this.loadData();
        if (prevState.show_notifications !== this.state.show_notifications && !this.state.show_notifications) this.props.onFullscreenChange(false);
        if (prevState.show_notifications !== this.state.show_notifications && this.state.show_notifications && window.innerWidth < 500) this.props.onFullscreenChange(true);
    }

    componentWillUnmount() {
        document.removeEventListener('mousedown', this.onClickOutside);
    }

    onClickOutside = (e) => {
        if (this.state.show_notifications && this.notificationBlockRef.current && ! this.notificationBlockRef.current.contains(e.target)) this.setState(
            { hidden_notifications: true }, 
            () => setTimeout(() => this.setState({ show_notifications: false, show_unreaded: true }), 400)
        );
    }

    loadData = () => {
        // Récupération des notifications non lues
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/notifications/for-user/' + this.props.user._id + '/unreaded'
        }).then((response) => {
            if (!response) {
                this.setState({
                    unreaded: null
                });
            }

            if (response.data) {
                this.setState({
                    unreaded: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });

        // Récupération de toutes les notifications
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/notifications/for-user/' + this.props.user._id
        }).then((response) => {
            if (!response) {
                this.setState({
                    all: null
                });
            }

            if (response.data) {
                this.setState({
                    all: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });        
    }

    onNotificationsClick = () => {
        if (this.state.show_notifications) {
            this.setState(
                { hidden_notifications: true }, 
                () => setTimeout(() => this.setState({ show_notifications: false, show_unreaded: true }), 400)
            );
        } else {
            this.setState(
                { show_notifications: true, show_unreaded: true }, 
                () => setTimeout(() => this.setState({ hidden_notifications: false }), 1)
            );
        }
    }

    onNotificationsToggle = () => {
        this.setState({ show_unreaded: !this.state.show_unreaded });
    }

    onAllMarkAsReadedClick = () => {
        // Marque toutes les notifications comme lues
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/notifications/mark-as-readed-all/' + this.props.user._id
        }).then((response) => {
            if (response.data) {
                this.loadData();
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    onDeleteAllClick = () => {
        // Suppression de toutes les notifications
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/auth/notifications/delete-all/' + this.props.user._id
        }).then((response) => {
            if (response.data) {
                this.loadData();
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    render() {
        return (
            <div className="Notifications" ref={this.notificationBlockRef}>
                <div className="notifications-label" onClick={this.onNotificationsClick}>
                    <Icon fontSize="small">notifications</Icon>
                    {
                        this.state.unreaded && this.state.unreaded.length > 0
                        && <div className="notifications-count">{this.state.unreaded.length}</div>
                    }
                </div>
                {
                    this.state.show_notifications
                    && <div className="notifications-container">
                        <div className={this.state.hidden_notifications ? "notifications-block hidden" : "notifications-block"}>
                            <div className="notifications-content">
                                <div className="notifications-toggle">
                                    <span 
                                        className={this.state.show_unreaded ? "active" : ""} 
                                        onClick={() => !this.state.show_unreaded ? this.onNotificationsToggle() : null}
                                    >Non lues</span>
                                    <span
                                        className={!this.state.show_unreaded ? "active" : ""} 
                                        onClick={() => this.state.show_unreaded ? this.onNotificationsToggle() : null}
                                    >Toutes</span>
                                </div>
                                <div className="notifications-content">
                                    {
                                        (
                                            this.state.show_unreaded && this.state.unreaded && this.state.unreaded.length > 0
                                            && <>
                                                {this.state.unreaded.map((notif, i) => <NotificationBlock 
                                                    key={i} 
                                                    notification={notif} 
                                                    loadData={this.loadData}
                                                    closeNotif={this.onNotificationsClick}
                                                />)}
                                                <button type="button" onClick={this.onAllMarkAsReadedClick}>Tout marquer comme lu</button>
                                            </>
                                        ) || (
                                            this.state.show_unreaded && (this.state.unreaded === null || this.state.unreaded.length === 0)
                                            && <p>Aucune notification non lue</p>
                                        )
                                    }
                                    {
                                        (
                                            !this.state.show_unreaded && this.state.all && this.state.all.length > 0
                                            && <> 
                                                {this.state.all.map((notif, i) => <NotificationBlock 
                                                    key={i} 
                                                    notification={notif} 
                                                    loadData={this.loadData}
                                                    closeNotif={this.onNotificationsClick}
                                                />)}
                                                <button type="button" onClick={this.onDeleteAllClick}>Tout supprimer</button>
                                            </>
                                        ) || (
                                            !this.state.show_unreaded && (this.state.all === null || this.state.all.length === 0)
                                            && <p>Aucune notification</p>
                                        )
                                    }
                                </div>
                            </div>
                        </div>
                    </div>
                }
            </div>
        );
    }
}

export default withRouter(Notifications);