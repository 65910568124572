import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import SelectField from '../../../../utils/SelectField';
import JWTManager from '../../../../utils/JWTManager';
import ROLES from '../../../../constants/roles.enum';
import './EditUser.scss';

class EditUser extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            initial_option: null,
            error_response: null
        }
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null && this.props.match.params.userId) this.loadData();
    }

    loadData = () => {
        // Récupération de l'image
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/users/' + this.props.match.params.userId
        }).then((response) => {
            if (!response) {
                this.setState({
                    user: null,
                    initial_option: null
                });
            }

            if (response.data) {
                this.setState({
                    user: response.data,
                    initial_option: this.getInitialOption(response.data.role)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getInitialOption = (role) => {
        switch (role) {
            case ROLES.ADMIN:
                return { value: ROLES.ADMIN, label: 'Administrateur' };
            case ROLES.EDITOR:
                return { value: ROLES.EDITOR, label: 'Éditeur' };
            case ROLES.USER:
                return { value: ROLES.USER, label: 'Utilisateur' };
            default:
                break;
        }
    }
 
    onSubmit = (user) => {
        // Mise à jour de l'image
        const id = toast.loading('Mise à jour de l\'utilisateur...', { toastId: 'edit-user-' + this.state.user.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/users/' + this.state.user.id + '/as-admin',
            data: {
                email: user.email,
                name: user.name,
                firstname: user.firstname,
                role: user.role.value,
                notif_email: user.notif_email
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Utilisateur modifié avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                if (this.props.connected_user_id === this.state.user.id) this.props.loadUser();
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de modifier l\'utilisateur',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="EditUser">
                <Formik
                    initialValues={{ 
                        email: this.state.user ? this.state.user.email : "",
                        name: this.state.user ? this.state.user.name : "",
                        firstname: this.state.user ? this.state.user.firstname : "",
                        role: this.state.initial_option ? this.state.initial_option : { value: ROLES.USER, label: 'Utilisateur' },
                        notif_email: this.state.user ? this.state.user.notif_email : ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            email: Yup.string()
                                .email("Veuillez saisir une adresse mail")
                                .required("Adresse mail requise"),
                            name: Yup.string()
                                .required("Nom requis"),
                            firstname: Yup.string()
                                .required("Prénom requis"),
                            role: Yup.object()
                                .required("Rôle requis"),
                            notif_email: Yup.boolean()
                        })
                    }
                    enableReinitialize={true}
                    onSubmit={(user) => this.onSubmit(user)}
                >
                    {({ errors, touched, values }) => (
                        <Form className="edit-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Mail*</label>
                                    <div className={touched.email && errors.email ? "field error" : "field"}>
                                        <Icon fontSize="small">alternate_email</Icon>
                                        <Field name="email" autoComplete="email" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="email" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Nom*</label>
                                    <div className={touched.name && errors.name ? "field error" : "field"}>
                                        <Icon fontSize="small">face</Icon>
                                        <Field name="name" autoComplete="family-name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="name" />
                                </div>
                                <div className="form-group">
                                    <label>Prénom*</label>
                                    <div className={touched.firstname && errors.firstname ? "field error" : "field"}>
                                        <Icon fontSize="small">face</Icon>
                                        <Field name="firstname" autoComplete="given-name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="firstname" />
                                </div>
                            </div>
                            <div className="form-row">
                            <div className="form-group">
                                    <label>Rôle*</label>
                                    <div className={touched.role && errors.role ? "field error" : "field"}>
                                        <Icon fontSize="small">security</Icon>
                                        <SelectField
                                            className="select"
                                            name="role"
                                            options={[{
                                                value: ROLES.ADMIN,
                                                label: 'Administrateur'
                                            }, {
                                                value: ROLES.EDITOR,
                                                label: 'Éditeur'
                                            }, {
                                                value: ROLES.USER,
                                                label: 'Utilisateur'
                                            }]}
                                            placeholder="Choisir un rôle..."
                                            noOptionsMessage={() => "Aucun rôle"}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="role" />
                                </div>
                                <div className="form-group checkbox">
                                    <div className={touched.notif_email && errors.notif_email ? "field checkbox notif error" : "field checkbox notif"}>
                                        <label>
                                            <span className="checkbox-input">
                                                <span className="checkbox-label">Notifications par mail</span>
                                                <Field type="checkbox" name="notif_email" checked={values.notif_email} />
                                                <Icon fontSize="tiny">{values.notif_email ? "notifications_active" : "notifications_off"}</Icon>
                                            </span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="notif_mail" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withRouter(EditUser);