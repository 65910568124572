import React, { createRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import Icon from '@mui/material/Icon';

import PhoneInputField from '../../../../utils/PhoneInputField';
import JWTManager from '../../../../utils/JWTManager';
import './EditSettings.scss';

class EditSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error_response: null,
            settings: null
        }

        this.editSettingsForm = createRef();
    }

    componentDidMount() {
        this.loadData();
    }

    loadData = () => {
        // Récupération de l'image
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/settings'
        }).then((response) => {
            if (!response) {
                this.setState({
                    settings: null
                });
            }

            if (response.data) {
                this.setState({
                    settings: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    onSubmit = (settings) => {
        const id = toast.loading('Mise à jour des paramètres du site...', { toastId: 'edit-settings' });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/settings/update',
            data: {
                phone: settings.phone,
                email: settings.email,
                forfait_menage: settings.forfait_menage ? Number(String(settings.forfait_menage).replace(/,/, '.')) : null,
                duree_preavis: settings.duree_preavis ? Number(String(settings.duree_preavis).replace(/,/, '.')) : null,
                airbnb_url: settings.airbnb_url,
                abritel_url: settings.abritel_url,
                facebook_url: settings.facebook_url,
                messenger_url: settings.messenger_url
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Paramètres du site mis à jour avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de mettre à jour les paramètres du site',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="EditSettings">
                <Formik
                    initialValues={{ 
                        phone: this.state.settings ? this.state.settings.phone : undefined,
                        email: this.state.settings ? this.state.settings.email : "",
                        forfait_menage: this.state.settings ? this.state.settings.forfait_menage : "",
                        duree_preavis: this.state.settings ? this.state.settings.duree_preavis : "",
                        airbnb_url: this.state.settings ? this.state.settings.airbnb_url : "",
                        abritel_url: this.state.settings ? this.state.settings.abritel_url : "",
                        refuge_url: process.env.REACT_APP_API_URL + "/events/events-to-ical",
                        facebook_url: this.state.settings ? this.state.settings.facebook_url : "",
                        messenger_url: this.state.settings ? this.state.settings.messenger_url : ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            phone: Yup.lazy((value) => {
                                if (value) {
                                    return Yup.string()
                                        .phone(null, false, "Veuillez saisir un numéro de téléphone")
                                        .notRequired()
                                        .default(undefined);
                                } else {
                                    return Yup.mixed().notRequired();
                                }
                            }),
                            email: Yup.string()
                                .email("Veuillez saisir une adresse mail"),
                            forfait_menage: Yup.number("Veuillez saisir un montant")
                                .transform((value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un montant")
                                .positive("Veuillez saisir un montant positif"),
                            duree_preavis: Yup.number("Veuillez saisir un montant")
                                .transform((value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un montant")
                                .positive("Veuillez saisir un montant positif")
                                .integer("Veuillez saisir une durée entière"),
                            airbnb_url: Yup.string()
                                .url("Veuillez saisir une URL"),
                            abritel_url: Yup.string()
                                .url("Veuillez saisir une URL"),
                            facebook_url: Yup.string()
                                .url("Veuillez saisir une URL"),
                            messenger_url: Yup.string()
                                .url("Veuillez saisir une URL")
                        })
                    }
                    innerRef={this.editSettingsForm}
                    enableReinitialize={true}
                    onSubmit={(settings) => this.onSubmit(settings)}
                >
                    {({ errors, touched }) => (
                        <Form className="settings-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Téléphone de contact</label>
                                    <div className={touched.phone && errors.phone ? "field error" : "field"}>
                                        <Icon fontSize="small">phone</Icon>
                                        <PhoneInputField name="phone" autoComplete="tel" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="phone" />
                                </div>
                                <div className="form-group">
                                    <label>Mail de contact</label>
                                    <div className={touched.email && errors.email ? "field error" : "field"}>
                                        <Icon fontSize="small">alternate_email</Icon>
                                        <Field name="email" autoComplete="email" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="email" />
                                </div>
                                <div className="stay-horizontal">
                                    <div className="form-group medium">
                                        <label>Forfait ménage</label>
                                        <div className={touched.forfait_menage && errors.forfait_menage ? "field error" : "field"}>
                                            <Icon fontSize="small">cleaning_services</Icon>
                                            <Field name="forfait_menage" />
                                            <span className="field-addon">&euro;</span>
                                        </div>
                                        <ErrorMessage component="div" className="error" name="forfait_menage" />
                                    </div>
                                    <div className="form-group medium">
                                        <label>Durée du préavis</label>
                                        <div className={touched.duree_preavis && errors.duree_preavis ? "field error" : "field"}>
                                            <Icon fontSize="small">hourglass_bottom</Icon>
                                            <Field name="duree_preavis" />
                                            <span className="field-addon">jour(s)</span>
                                        </div>
                                        <ErrorMessage component="div" className="error" name="duree_preavis" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>URL du calendrier Airbnb</label>
                                    <div className={touched.airbnb_url && errors.airbnb_url ? "field error" : "field"}>
                                        <Icon fontSize="small">link</Icon>
                                        <Field name="airbnb_url" autoComplete="url" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="airbnb_url" />
                                </div>
                                <div className="form-group">
                                    <label>URL du calendrier Abritel</label>
                                    <div className={touched.abritel_url && errors.abritel_url ? "field error" : "field"}>
                                        <Icon fontSize="small">link</Icon>
                                        <Field name="abritel_url" autoComplete="url" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="abritel_url" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>URL de la page Facebook</label>
                                    <div className={touched.facebook_url && errors.facebook_url ? "field error" : "field"}>
                                        <i className="fa-brands fa-facebook"></i>
                                        <Field name="facebook_url" autoComplete="url" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="facebook_url" />
                                </div>
                                <div className="form-group">
                                    <label>URL de contact Messenger</label>
                                    <div className={touched.messenger_url && errors.messenger_url ? "field error" : "field"}>
                                        <i className="fa-brands fa-facebook-messenger"></i>
                                        <Field name="messenger_url" autoComplete="url" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="messenger_url" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>URL de synchronisation du calendrier du site</label>
                                    <div className="field">
                                        <Icon fontSize="small">link</Icon>
                                        <Field name="refuge_url" readOnly/>
                                    </div>
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default EditSettings;