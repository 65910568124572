import React from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';
import Parser from 'html-react-parser';

import Table from '../table/Table';
import Confirmation from '../confirmation/Confirmation';
import Overlay from '../overlay/Overlay';
import CreateSlide from './create_slide/CreateSlide';
import EditSlide from './edit_slide/EditSlide';
import NotFound from '../../not_found/NotFound';
import JWTManager from '../../../utils/JWTManager';
import FormatManager from '../../../utils/FormatManager';
import TEXT_POSITION from '../../../constants/text_position.enum';
import './Slideshow.scss';

class Slideshow extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            slides: null,
            slides_id: null,
            show_delete: false,
            slide_delete_id: null,
            show_edit: false,
            show_create: false
        }
    }

    componentDidMount() {
        this.checkPath();
        setTimeout(() => this.setState({ hidden: false }), 1);
        if (JWTManager.getToken() !== null) this.loadData();
        animateScroll.scrollTo(0, {
            duration: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) this.checkPath();
    }

    checkPath = () => {
        this.setState({ 
            show_delete: this.props.location.pathname.includes('/delete'),
            show_edit: this.props.location.pathname.includes('/edit'),
            show_create: this.props.location.pathname.includes('/create')
        });
        this.props.onFullscreenChange(
            this.props.location.pathname.includes('/create') ||
            this.props.location.pathname.includes('/edit') ||
            this.props.location.pathname.includes('/delete')
        );
    }

    loadData = () => {
        // Récupération des slides
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/slideshow'
        }).then((response) => {
            if (!response) {
                this.setState({
                    slides: null,
                    slides_id: null
                });
            }

            if (response.data) {
                this.setState({
                    slides: response.data,
                    slides_id: this.getSlidesId(response.data)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getSlidesId = (slides) => {
        return slides.map(slide => slide.id);
    }

    getColumns = () => {
        return [
            {
                Header: 'Image',
                id: 'image',
                disableSortBy: true,
                className: 'image',
                Cell: tableProps => <Link to={"/images/" + tableProps.row.original.image._id + "/edit"}><img 
                    srcSet={FormatManager.imageSrcSet(tableProps.row.original.image.paths)}
                    sizes="16rem"
                    src={process.env.REACT_APP_API_URL + tableProps.row.original.image.paths.w1920} 
                    alt='' 
                /></Link>
            }, {
                Header: 'Texte',
                id: 'text',
                className: 'text',
                disableSortBy: true,
                Cell: tableProps => (tableProps.row.original.text && Parser(tableProps.row.original.text)) || null
            }, {
                Header: 'Position du texte',
                id: 'text_position',
                disableSortBy: true,
                Cell: tableProps => tableProps.row.original.text_position === TEXT_POSITION.TOP_LEFT
                    ? 'En haut à gauche'
                    : tableProps.row.original.text_position === TEXT_POSITION.TOP_RIGHT
                        ? 'En haut à droite'
                        : tableProps.row.original.text_position === TEXT_POSITION.BOTTOM_RIGHT
                            ? 'En bas à droite'
                            : tableProps.row.original.text_position === TEXT_POSITION.BOTTOM_LEFT
                                ? 'En bas à gauche'
                                : null
            }, {
                Header: '',
                id: 'edit-column',
                disableSortBy: true,
                className: "actions",
                Cell: tableProps => <div className="action-buttons">
                    <button className="switch-button" onClick={() => this.onSwitchClick(tableProps.row.original.id)} disabled={!this.canPrevious(tableProps.row.original.id)}>
                        <Icon fontSize="tiny">arrow_upward</Icon>
                    </button>
                    <button className="switch-button" onClick={() => this.onSwitchClick(tableProps.row.original.id, false)} disabled={!this.canNext(tableProps.row.original.id)}>
                        <Icon fontSize="tiny">arrow_downward</Icon>
                    </button>
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/edit"} className="edit-button">
                        <Icon fontSize="tiny">edit</Icon><span>Modifier</span>
                    </Link>
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/delete"} className="delete-button">
                        <Icon fontSize="tiny">delete</Icon><span>Supprimer</span>
                    </Link>
                </div>
            }
        ];
    }

    switchSlides = (current_id, other_id) => {
        // Inversion des slides
        const id = toast.loading('Inversion des diapositives...', { toastId: 'switch-slides-' + current_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/slideshow/switch-order',
            data: {
                current_id: current_id,
                other_id: other_id
            }
        }).then((response) => {
            if (response.data.success) {
                toast.update(id, {
                    render: 'Diapositives inversées avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
            }
        }).catch((error) => {
            console.error(error.response);
            toast.update(id, {
                render: 'Impossible d\'inverser les diapositives',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000
            });
        });
    }

    onSwitchClick = (row, up = true) => {
        if (up) {
            const other = this.getPreviousRow(row);
            if (other) this.switchSlides(row, other);
        } else {
            const other = this.getNextRow(row);
            if (other) this.switchSlides(row, other);
        }
    }

    getPreviousRow = (row) => {
        return this.state.slides_id[this.state.slides_id.indexOf(row) - 1];
    }

    getNextRow = (row) => {
        return this.state.slides_id[this.state.slides_id.indexOf(row) + 1];
    }

    canPrevious = (row) => {
        return this.state.slides_id.indexOf(row) !== 0;
    }

    canNext = (row) => {
        return this.state.slides_id.indexOf(row) !== this.state.slides_id.length - 1;
    }

    onDeleteOpen = (row) => {
        this.setState({
            show_delete: true,
            slide_delete_id: row
        });
        this.props.onFullscreenChange(true);
    }

    onDeleteAnnulation = () => {
        this.setState({
            show_delete: false,
            slide_delete_id: null
        });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onDeleteConfirmation = () => {
        // Suppression de la slide
        const id = toast.loading('Suppression de la diapositive...', { toastId: 'delete-slide-' + this.state.slide_delete_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/auth/slideshow/' + this.state.slide_delete_id,
        }).then((response) => {
            if (response.data.success) {
                this.setState({
                    show_delete: false,
                    slide_delete_id: null
                });
                this.props.onFullscreenChange(false);
                toast.update(id, {
                    render: 'Diapositive supprimée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
                setTimeout(() => this.props.history.push(this.props.match.url), 400);
            }
        }).catch((error) => {
            console.error(error.response);
            toast.update(id, {
                render: 'Impossible de supprimer la diapositive',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000
            });
        });
    }

    onEditClose = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onEditSubmit = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateClose = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateSubmit = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    render() {
        return (
            <div className={this.state.hidden ? "Slideshow hidden" : "Slideshow"}>
                <h2 className={this.state.hidden ? "hidden" : ""}>Diaporama</h2>
                <div className="slides-content">
                    <div className="slides-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">collections</Icon><span>Toutes les diapositives</span></h3>
                            <Link to={this.props.match.url + "/create"} className="create-button">
                                <Icon fontSize="small">add_photo_alternate</Icon><span>Ajouter une diapositive</span>
                            </Link>
                        </div>
                        <div className="block-content">
                            {
                                (this.state.slides === null || this.state.slides.length === 0)
                                && <p>Aucune diapositive</p>
                            }
                            {
                                this.state.slides && this.state.slides.length > 0
                                && <Table data={this.state.slides} columns={this.getColumns()} pageSize={5} />
                            }
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route path={this.props.match.path + "/:slideId/edit"} exact>
                        <Overlay
                            title="Modification de la diapositive"
                            onClose={this.onEditClose}
                            show_overlay={this.state.show_edit}
                        >
                            <EditSlide
                                onSubmit={this.onEditSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/:elementId/delete"} exact>
                        <Confirmation
                            title="Voulez-vous supprimer cette diapositive ?"
                            onOpen={this.onDeleteOpen}
                            onCancel={this.onDeleteAnnulation}
                            onConfirm={this.onDeleteConfirmation}
                            show_confirmation={this.state.show_delete}
                        />
                    </Route>
                    <Route path={this.props.match.path + "/create"} exact>
                        <Overlay 
                            title="Nouvelle diapositive"
                            onClose={this.onCreateClose}
                            show_overlay={this.state.show_create}
                        >
                            <CreateSlide 
                                onSubmit={this.onCreateSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/*"}>
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Slideshow);