import React from 'react';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';

import MenuItem from './menu-item/MenuItem';
import './Sidebar.scss';

class Sidebar extends React.Component {
    componentDidUpdate(prevProps) {
        if (
            (prevProps.menuOpen !== this.props.menuOpen && !this.props.menuOpen) 
            || (prevProps.reducedMenu !== this.props.reducedMenu && this.props.reducedMenu)
        ) animateScroll.scrollTo(0, {
            duration: 0,
            delay: 400,
            containerId: "sidebar-container"
        });
    }

    onLogoutClick = (event) => {
        event.preventDefault();
        this.props.onUserLogout();
    }

    render() {
        return (
            <div className={this.props.reducedMenu ? this.props.menuOpen ? "Sidebar reduced open" : "Sidebar reduced" : "Sidebar"} id="sidebar-container" ref={this.props.innerRef}>
                <div className="menu-section">
                    <ul>
                        <MenuItem path="/" icon="home" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Accueil</MenuItem>
                    </ul>
                </div>
                <div className="menu-section">
                    <h3>Réservations</h3>
                    <ul>
                        <MenuItem path="/reservations" icon="confirmation_number" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Réservations</MenuItem>
                        <MenuItem path="/calendrier" icon="today" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Calendrier</MenuItem>
                        <MenuItem path="/avis" icon="question_answer" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Avis</MenuItem>
                    </ul>
                </div>
                <div className="menu-section">
                    <h3>Tarification</h3>
                    <ul>
                        <MenuItem path="/periodes" icon="calendar_view_month" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Périodes</MenuItem>
                        <MenuItem path="/tarifs" icon="payment" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Tarifs</MenuItem>
                    </ul>
                </div>
                <div className="menu-section">
                    <h3>Contenu</h3>
                    <ul>
                        <MenuItem path="/accueil" icon="home_work" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Page d'Accueil</MenuItem>
                        <MenuItem path="/diaporama" icon="slideshow" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Diaporama</MenuItem>
                        <MenuItem path="/le-chalet" icon="cottage" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Le Chalet</MenuItem>
                        <MenuItem path="/activites" icon="local_activity" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Activités</MenuItem>
                        <MenuItem path="/images" icon="collections" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Images</MenuItem>
                        <MenuItem path="/mentions-legales" icon="policy" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Mentions légales</MenuItem>
                        <MenuItem path="/cg-vente" icon="point_of_sale" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>CGV</MenuItem>
                    </ul>
                </div>
                <div className="menu-section">
                    <h3>Paramètres</h3>
                    <ul>
                        <MenuItem path="/utilisateurs" icon="people_alt" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Utilisateurs</MenuItem>
                        <MenuItem path="/parametres" icon="settings" user={this.props.user} setMenuOpen={this.props.setMenuOpen}>Paramètres</MenuItem>
                    </ul>
                </div>
                <button type="button" onClick={(event) => this.onLogoutClick(event)}>
                    <Icon fontSize="small">logout</Icon>
                    <span>Déconnexion</span>
                </button>
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => <Sidebar innerRef={ref} {...props} />);