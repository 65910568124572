import React from 'react';
import { Link, withRouter } from 'react-router-dom';

import './NotFound.scss';

class NotFound extends React.Component {
    render() {
        return (
            <div className="NotFound">
                <h1><img src="/logo/logo_simple.svg" alt="" />404</h1>
                <h2>Page non trouvée</h2>
                <p>Aucune correspondance pour {this.props.location.pathname}</p>
                <Link to="/">Retourner à l'accueil</Link>
            </div>
        );
    }
}

export default withRouter(NotFound);