import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, ErrorMessage } from 'formik';
import Icon from '@mui/material/Icon';
import * as Yup from 'yup';
import moment from 'moment';

import DatePickerField from '../../../../utils/date_picker_field/DatePickerField';
import Confirmation from '../../confirmation/Confirmation';
import JWTManager from '../../../../utils/JWTManager';
import FormatManager from '../../../../utils/FormatManager';
import './EditLockedEvent.scss';

class EditLockedEvent extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            event: null,
            inavailable_dates: null,
            error_response: null,
            show_confirmation: false,
            start: null,
            end: null
        }

        this.endEdit = createRef();
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null && this.props.match.params.eventId) this.loadData();
    }

    loadData = () => {
        // Récupération de l'événement
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/events/' + this.props.match.params.eventId
        }).then((response) => {
            if (!response) {
                this.setState({
                    event: null,
                    start: null,
                    end: null,
                });
            }
            if (response.data) {
                this.setState({
                    event: response.data,
                    start: response.data.start ? new Date(response.data.start) : null,
                    end: response.data.end ? new Date(response.data.end) : null
                });
                this.getInavailableDates();
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(error.response.data.message);
        });
    }

    getInavailableDates = () => {
        // Récupération des dates non disponibles
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/events/inavailable-dates/' + this.state.event.id
        }).then((response) => {
            if (!response) {
                this.setState({
                    inavailable_dates: null
                });
            }

            if (response.data) {
                this.setState({
                    inavailable_dates: FormatManager.datesArray(response.data.inavailableDates)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    onClickDelete = (e) => {
        e.preventDefault();
        this.setState({
            show_confirmation: true,
        });
    }

    onDeleteAnnulation = (e) => {
        e.preventDefault();
        this.setState({
            show_confirmation: false,
            form_error: false
        });
    }

    onDeleteConfirmation = (e) => {
        e.preventDefault();
        const id = toast.loading('Suppression de la période bloquée...', { toastId: 'delete-locked-period-' + this.state.event.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/auth/events/' + this.state.event.id,
        }).then((response) => {
            if (response.data.success) {
                this.setState({
                    show_confirmation: false
                });
                toast.update(id, {
                    render: 'Période bloquée supprimée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            console.error(error.response);
            toast.update(id, {
                render: 'Impossible de supprimer la période bloquée',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000
            });
        });
    }

    onSubmit = (event) => {
        const id = toast.loading('Mise à jour de la période bloquée...', { toastId: 'edit-locked-period-' + this.state.event.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/events/' + this.state.event.id,
            data: {
                start: FormatManager.dateDBFormat(event.start),
                end: FormatManager.dateDBFormat(event.end)
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Période mise à jour avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message,
                    form_error: true
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de mettre à jour la période bloquée',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    showStartMonth = () => {
        if (!this.state.start) return;
        if (moment(this.state.end).diff(moment(this.state.start), 'months') < 1) this.endEdit.current.getDayPicker().showMonth(this.state.start);
    }
    
    handleStartChange = (start) => {
        this.setState({ start: start });
    }
    
    handleEndChange = (end) => {
        this.setState({ end: end }, this.showStartMonth);
    }

    render() {
        const { start, end } = this.state;
        const modifiers = { start: start, end: end };

        return (
            <div className="EditLockedEvent">
                <Formik
                    initialValues={{ 
                        start: this.state.event ? new Date(this.state.event.start) : "",
                        end: this.state.event ? new Date(this.state.event.end) : ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            start: Yup.date()
                                .transform(FormatManager.parseDateString)
                                .typeError("Veuillez saisir une date")
                                .required("Date de début requise"),
                            end: Yup.date("Veuillez saisir une date")
                                .transform(FormatManager.parseDateString)
                                .typeError("Veuillez saisir une date")
                                .required("Date de fin requise")
                        })
                    }
                    enableReinitialize={true}
                    onSubmit={(event) => this.onSubmit(event)}
                >
                    {({ errors, touched }) => (
                        <Form className="edit-form" autoComplete="off">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Date de début*</label>
                                    <div className={touched.start && errors.start ? "field error" : "field"}>
                                        <Icon fontSize="small">today</Icon>
                                        <DatePickerField
                                            name="start"
                                            value={start}
                                            dayPickerProps={{ 
                                                selectedDays: [start, { from: start, to: end }],
                                                disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: new Date(), after: end }) : [],
                                                month: new Date(),
                                                fromMonth: new Date(),
                                                toMonth: end,
                                                modifiers: modifiers,
                                                onDayClick: () => this.endEdit.current.getInput().focus(),
                                                className: "dates-range"
                                            }}
                                            onDayChange={this.handleStartChange}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="start" />
                                </div>
                                <div className="form-group">
                                    <label>Date de fin*</label>
                                    <div className={touched.end && errors.end ? "field error" : "field"}>
                                        <Icon fontSize="small">today</Icon>
                                        <DatePickerField
                                            name="end"
                                            ref={this.endEdit}
                                            value={end}
                                            dayPickerProps={{ 
                                                selectedDays: [start, { from: start, to: end }],
                                                disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: start }) : [],
                                                modifiers: modifiers,
                                                month: start,
                                                fromMonth: start,
                                                className: "dates-range"
                                            }}
                                            onDayChange={this.handleEndChange}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="end" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button onClick={(e) => this.onClickDelete(e)} className="delete-button">
                                    <Icon fontSize="small">delete</Icon><span>Supprimer</span>
                                </button>
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
                <Confirmation
                    title="Voulez-vous supprimer cette période bloquée ?"
                    onCancel={(e) => this.onDeleteAnnulation(e)}
                    onConfirm={(e) => this.onDeleteConfirmation(e)}
                    show_confirmation={this.state.show_confirmation}
                />
            </div>
        );
    }
}

export default withRouter(EditLockedEvent);