import React from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import JWTManager from '../../../../utils/JWTManager';
import './EditPricing.scss';

class EditPricing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            pricing: null,
            error_response: null
        }
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null && this.props.match.params.pricingId) this.loadData();
    }

    loadData = () => {
        // Récupération du tarif
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/pricings/' + this.props.match.params.pricingId
        }).then((response) => {
            if (!response) {
                this.setState({
                    pricing: null
                });
            }

            if (response.data) {
                this.setState({
                    pricing: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(error.response.data.message);
        });
    }

    onSubmit = (pricing) => {
        // Mise à jour du tarif
        const id = toast.loading('Mise à jour du tarif...', { toastId: 'edit-pricing-' + this.state.pricing.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/pricings/' + this.state.pricing.id,
            data: {
                name: pricing.name,
                week_pricing: pricing.week_pricing ? Number(String(pricing.week_pricing).replace(/,/, '.')) : null,
                weekend_pricing: pricing.weekend_pricing ? Number(String(pricing.weekend_pricing).replace(/,/, '.')) : null,
                week_only: pricing.week_only,
                week_reduction: pricing.week_reduction ? Number(String(pricing.week_reduction).replace(/,/, '.')) : null,
                week_special: pricing.week_special
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Tarif modifié avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de modifier le tarif',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="EditPricing">
                <Formik
                    initialValues={{ 
                        name: this.state.pricing ? this.state.pricing.name : "",
                        week_pricing: this.state.pricing && this.state.pricing.week_pricing ? this.state.pricing.week_pricing : "",
                        weekend_pricing: this.state.pricing && this.state.pricing.weekend_pricing ? this.state.pricing.weekend_pricing : "",
                        week_only: this.state.pricing ? this.state.pricing.week_only : false,
                        week_reduction: this.state.pricing && this.state.pricing.week_reduction ? this.state.pricing.week_reduction : "",
                        week_special: this.state.pricing ? this.state.pricing.week_special : false
                    }}
                    validationSchema={
                        Yup.object().shape({
                            name: Yup.string()
                                .required("Nom du tarif requis"),
                            week_pricing: Yup.number("Veuillez saisir un montant")
                                .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un montant")
                                .positive("Veuillez saisir un montant positif")
                                .required("Tarif semaine requis"),
                            weekend_pricing: Yup.number("Veuillez saisir un montant")
                                .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un montant")
                                .positive("Veuillez saisir un montant positif")
                                .required("Tarif week-end requis"),
                            week_only: Yup.boolean(),
                            week_reduction: Yup.number("Veuillez saisir un pourcentage")
                                .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un pourcentage")
                                .positive("Veuillez saisir un pourcentage positif")
                                .max(100, "Veuillez saisir un pourcentage inférieur à 100"),
                            week_special: Yup.boolean()
                        })
                    }
                    enableReinitialize={true}
                    onSubmit={(pricing) => this.onSubmit(pricing)}
                >
                    {({ errors, touched }) => (
                        <Form className="edit-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Nom du tarif*</label>
                                    <div className={touched.name && errors.name ? "field error" : "field"}>
                                        <Icon fontSize="small">payment</Icon>
                                        <Field name="name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="name" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Tarif semaine*</label>
                                    <div className={touched.week_pricing && errors.week_pricing ? "field error" : "field"}>
                                        <Icon fontSize="small">price_change</Icon>
                                        <Field name="week_pricing" />
                                        <span className="field-addon">&euro;</span>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="week_pricing" />
                                </div>
                                <div className="form-group">
                                    <label>Tarif week-end*</label>
                                    <div className={touched.weekend_pricing && errors.weekend_pricing ? "field error" : "field"}>
                                        <Icon fontSize="small">price_change</Icon>
                                        <Field name="weekend_pricing" />
                                        <span className="field-addon">&euro;</span>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="weekend_pricing" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group checkbox">
                                    <div className={touched.week_only && errors.week_only ? "field checkbox error" : "field checkbox"}>
                                        <label>
                                            <span className="checkbox-input">
                                                <span className="checkbox-label">Semaine uniquement</span>
                                                <Field type="checkbox" name="week_only" />
                                                <span className="checkbox-control"><Icon fontSize="tiny">checked</Icon></span>
                                            </span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="week_only" />
                                </div>
                                <div className="form-group">
                                    <label>Réduction semaine</label>
                                    <div className={touched.week_reduction && errors.week_reduction ? "field error" : "field"}>
                                        <Icon fontSize="small">local_offer</Icon>
                                        <Field name="week_reduction" />
                                        <span className="field-addon">%</span>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="week_reduction" />
                                </div>
                                <div className="form-group checkbox">
                                    <div className={touched.week_special && errors.week_special ? "field checkbox error" : "field checkbox"}>
                                        <label>
                                            <span className="checkbox-input">
                                                <span className="checkbox-label">Semaine spéciale</span>
                                                <Field type="checkbox" name="week_special" />
                                                <span className="checkbox-control"><Icon fontSize="tiny">checked</Icon></span>
                                            </span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="week_special" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withRouter(EditPricing);