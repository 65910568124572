import React from 'react';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';

import EditSettings from './edit_settings/EditSettings';
import EditUserSettings from './edit_user_settings/EditUserSettings';
import ChangePassword from './change_password/ChangePassword';
import './Settings.scss';

class Settings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true
        }
    }

    componentDidMount() {
        this.props.onFullscreenChange(false);
        setTimeout(() => this.setState({ hidden: false }), 1);
        animateScroll.scrollTo(0, {
            duration: 0
        });
    }

    render() {
        return (
            <div className={this.state.hidden ? "Settings hidden" : "Settings"}>
                <h2 className={this.state.hidden ? "hidden" : ""}>Paramètres</h2>
                <div className="settings-content">
                    <div className="settings-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">settings</Icon><span>Paramètres du site</span></h3>
                        </div>
                        <div className="block-content">
                            <EditSettings />
                        </div>
                    </div>
                </div>
                <div className="settings-content">
                    <div className="settings-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">account_circle</Icon><span>Paramètres utilisateur</span></h3>
                        </div>
                        <div className="block-content">
                            <EditUserSettings 
                                user={this.props.user}
                                loadUser={this.props.loadUser}
                            />
                        </div>
                        <div className="block-content change-password">
                            <ChangePassword user={this.props.user} />
                        </div>
                    </div>
                </div>
            </div>
        );
    }
}

export default Settings;