import React from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';
import Parser from 'html-react-parser';

import Table from '../table/Table';
import Confirmation from '../confirmation/Confirmation';
import Overlay from '../overlay/Overlay';
import EditComment from './edit_comment/EditComment';
import CreateComment from './create_comment/CreateComment';
import NotFound from '../../not_found/NotFound';
import JWTManager from '../../../utils/JWTManager';
import FormatManager from '../../../utils/FormatManager';
import './Reviews.scss';

class Reviews extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            without_comment: null,
            reviews: null,
            show_delete: false,
            review_delete_id: null,
            show_edit: false,
            show_create: false
        }
    }

    componentDidMount() {
        this.checkPath();
        setTimeout(() => this.setState({ hidden: false }), 1);
        if (JWTManager.getToken() !== null) this.loadData();
        animateScroll.scrollTo(0, {
            duration: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) this.checkPath();
    }

    checkPath = () => {
        this.setState({ 
            show_delete: this.props.location.pathname.includes('/delete'),
            show_edit: this.props.location.pathname.includes('/edit_comment'),
            show_create: this.props.location.pathname.includes('/create_comment')
        });
        this.props.onFullscreenChange(
            this.props.location.pathname.includes('/create_comment') ||
            this.props.location.pathname.includes('/edit_comment') ||
            this.props.location.pathname.includes('/delete')
        );
    }

    loadData = () => {
        // Récupération des avis sans commentaire
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/reviews/without-comment'
        }).then((response) => {
            if (!response) {
                this.setState({
                    without_comment: null
                });
            }
            if (response.data) {
                this.setState({
                    without_comment: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });

        // Récupération des avis
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/reviews'
        }).then((response) => {
            if (!response) {
                this.setState({
                    reviews: null
                });
            }
            if (response.data) {
                this.setState({
                    reviews: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getColumnsWithoutComment = () => {
        return [
            {
                Header: 'Date',
                accessor: 'date',
                className: 'main',
                Cell: tableProps => FormatManager.dateTimeLocaleFormat(tableProps.row.original.date)
            }, {
                Header: 'Locataire',
                accessor: 'name',
                className: 'main',
                Cell: tableProps => tableProps.row.original.name.toUpperCase() + ' ' + tableProps.row.original.firstname
            }, {
                Header: 'Note',
                accessor: 'note',
                className: 'center note',
                Cell: tableProps => <div className="stars-container">
                    {
                        tableProps.row.original.note >= 1 
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 0.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                    {
                        tableProps.row.original.note >= 2
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 1.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                    {
                        tableProps.row.original.note >= 3 
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 2.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                    {
                        tableProps.row.original.note >= 4
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 3.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                    {
                        tableProps.row.original.note >= 5
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 4.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                </div>
            }, {
                Header: 'Avis',
                accessor: 'review',
                className: 'text',
                disableSortBy: true,
                Cell: tableProps => tableProps.row.original.review ? Parser(tableProps.row.original.review) : null
            }, {
                Header: '',
                id: 'edit-column',
                disableSortBy: true,
                className: 'actions',
                Cell: tableProps => <div className="action-buttons">
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/create_comment"} className="validate-button">
                        <Icon fontSize="tiny">add_comment</Icon><span>Ajouter un commentaire</span>
                    </Link>
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/delete"} className="delete-button">
                        <Icon fontSize="tiny">delete</Icon><span>Supprimer</span>
                    </Link>
                </div>
            }
        ];
    }

    getColumns = () => {
        return [
            {
                Header: 'Date',
                accessor: 'date',
                className: 'main',
                Cell: tableProps => FormatManager.dateTimeLocaleFormat(tableProps.row.original.date)
            }, {
                Header: 'Locataire',
                accessor: 'name',
                className: 'main',
                Cell: tableProps => tableProps.row.original.name.toUpperCase() + ' ' + tableProps.row.original.firstname
            }, {
                Header: 'Note',
                accessor: 'note',
                className: 'center note',
                Cell: tableProps => <div className="stars-container">
                    {
                        tableProps.row.original.note >= 1 
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 0.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                    {
                        tableProps.row.original.note >= 2
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 1.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                    {
                        tableProps.row.original.note >= 3 
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 2.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                    {
                        tableProps.row.original.note >= 4
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 3.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                    {
                        tableProps.row.original.note >= 5
                            ? <Icon fontSize="tiny">star_rate</Icon>
                            : tableProps.row.original.note === 4.5
                                ? <Icon fontSize="tiny">star_half</Icon>
                                : <Icon fontSize="tiny">star_border</Icon>
                    }
                </div>
            }, {
                Header: 'Avis',
                accessor: 'review',
                className: 'text',
                disableSortBy: true,
                Cell: tableProps => tableProps.row.original.review ? Parser(tableProps.row.original.review) : null
            }, {
                Header: 'Votre commentaire',
                accessor: 'comment.comment',
                className: 'text',
                disableSortBy: true,
                Cell: tableProps => tableProps.row.original.comment && tableProps.row.original.comment.comment ? Parser(tableProps.row.original.comment.comment) : null
            }, {
                Header: '',
                id: 'edit-column',
                disableSortBy: true,
                className: 'actions',
                Cell: tableProps => <div className="action-buttons">
                    {
                        tableProps.row.original.comment
                        && <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/edit_comment"} className="edit-button">
                            <Icon fontSize="tiny">edit</Icon><span>Modifier votre commentaire</span>
                        </Link>
                    }
                    {
                        !tableProps.row.original.comment && tableProps.row.original.review.replace(/<[^>]*>?/gm, '').trim() !== ''
                        && <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/create_comment"} className="validate-button">
                            <Icon fontSize="tiny">add_comment</Icon><span>Ajouter un commentaire</span>
                        </Link>
                    }
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/delete"} className="delete-button">
                        <Icon fontSize="tiny">delete</Icon><span>Supprimer</span>
                    </Link>
                </div>
            }
        ];
    }

    onDeleteOpen = (row) => {
        this.setState({
            show_delete: true,
            review_delete_id: row
        });
        this.props.onFullscreenChange(true);
    }

    onDeleteAnnulation = () => {
        this.setState({
            show_delete: false,
            review_delete_id: null
        });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onDeleteConfirmation = () => {
        // Suppression de l'avis
        const id = toast.loading('Suppression de l\'avis...', { toastId: 'delete-review-' + this.state.review_delete_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/auth/reviews/' + this.state.review_delete_id,
        }).then((response) => {
            if (response.data.success) {
                this.setState({
                    show_delete: false,
                    review_delete_id: null
                });
                this.props.onFullscreenChange(false);
                toast.update(id, {
                    render: 'Avis supprimé avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
                setTimeout(() => this.props.history.push(this.props.match.url), 400);
            }
        }).catch((error) => {
            console.error(error.response);
            toast.update(id, {
                render: 'Impossible de supprimer l\'avis',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000
            });
        });
    }

    onEditClose = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onEditSubmit = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateClose = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateSubmit = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    render() {
        return (
            <div className={this.state.hidden ? "Reviews hidden" : "Reviews"}>
                <h2 className={this.state.hidden ? "hidden" : ""}>Avis</h2>
                <div className="reviews-content">
                    <div className="reviews-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">question_answer</Icon><span>Avis en attente de commentaire</span></h3>
                        </div>
                        <div className="block-content">
                            {
                                (this.state.without_comment === null || this.state.without_comment.length === 0)
                                && <p>Aucun avis en attente</p>
                            }
                            {
                                this.state.without_comment && this.state.without_comment.length > 0
                                && <Table data={this.state.without_comment} columns={this.getColumnsWithoutComment()} pageSize={5} />
                            }
                        </div>
                    </div>
                </div>
                <div className="reviews-content">
                    <div className="reviews-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">question_answer</Icon><span>Tous les avis</span></h3>
                        </div>
                        <div className="block-content">
                            {
                                (this.state.reviews === null || this.state.reviews.length === 0)
                                && <p>Aucun avis</p>
                            }
                            {
                                this.state.reviews && this.state.reviews.length > 0
                                && <Table data={this.state.reviews} columns={this.getColumns()} pageSize={10} />
                            }
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route path={this.props.match.path + "/:reviewId/edit_comment"} exact>
                        <Overlay
                            title="Modification du commentaire"
                            onClose={this.onEditClose}
                            show_overlay={this.state.show_edit}
                        >
                            <EditComment
                                onSubmit={this.onEditSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/:reviewId/create_comment"} exact>
                        <Overlay 
                            title="Nouveau commentaire"
                            onClose={this.onCreateClose}
                            show_overlay={this.state.show_create}
                        >
                            <CreateComment
                                onSubmit={this.onCreateSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/:elementId/delete"} exact>
                        <Confirmation
                            title="Voulez-vous supprimer cet avis ?"
                            onOpen={this.onDeleteOpen}
                            onCancel={this.onDeleteAnnulation}
                            onConfirm={this.onDeleteConfirmation}
                            show_confirmation={this.state.show_delete}
                        />
                    </Route>
                    <Route path={this.props.match.path + "/*"}>
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Reviews);