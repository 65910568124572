import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import SelectField from '../../../../utils/SelectField';
import EditorField from '../../../../utils/editor_field/EditorField';
import FormatManager from '../../../../utils/FormatManager';
import JWTManager from '../../../../utils/JWTManager';
import TEXT_POSITION from '../../../../constants/text_position.enum';
import './CreateSlide.scss';

class CreateSlide extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editor_state: FormatManager.htmlToEditor(),
            images: null,
            images_options: null,
            error_response: null
        }
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null) this.loadData();
    }

    loadData = () => {
        // Récupération des images
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/images'
        }).then((response) => {
            if (!response) {
                this.setState({
                    images: null,
                    images_options: null
                });
            }

            if (response.data) {
                this.setState({
                    images: response.data,
                    images_options: this.getImagesOptions(response.data)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getImagesOptions = (images) => {
        return images.map(image => ({
            value: image.id,
            label: <div className='image-option'>
                <img 
                    srcSet={FormatManager.imageSrcSet(image.paths)}
                    sizes="6rem"
                    src={image.paths.w1920}
                    alt = ''     
                />
                <span>{image.name}</span>
            </div>
        }));
    }

    imagesFilter = (option, searchText) => {
        return option.data.label.props.children[1].props.children.toLowerCase().includes(searchText.toLowerCase());
    }

    onEditorStateChange = (editorState, setFieldValue) => {
        setFieldValue('editorState', editorState);
    }

    onSubmit = (slide) => {
        const id = toast.loading('Création de la diapositive...', { toastId: 'create-slide' });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/slideshow/create',
            data: {
                image: slide.image.value,
                text: FormatManager.editorToHtml(slide.editorState),
                text_position: slide.text_position
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Diapositive créée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de créer la diapositive',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="CreateSlide">
                <Formik
                    initialValues={{ 
                        image: "",
                        editorState: this.state.editor_state,
                        text_position: TEXT_POSITION.BOTTOM_LEFT
                    }}
                    validationSchema={
                        Yup.object().shape({
                            image: Yup.mixed()
                                .required("Image requise"),
                            editorState: Yup.object(),
                            text_position: Yup.string()
                        })
                    }
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="create-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Image*</label>
                                    <div className={touched.image && errors.image ? "field error" : "field"}>
                                        <Icon fontSize="small">image</Icon>
                                        <SelectField
                                            className="select-image"
                                            name="image"
                                            options={this.state.images_options}
                                            filterOption={this.imagesFilter}
                                            placeholder="Choisir une image..."
                                            noOptionsMessage={() => "Aucune image"}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="image" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Texte</label>
                                    <div className={touched.editorState && errors.editorState ? "field editor error" : "field editor"}>
                                        <Icon fontSize="small">article</Icon>
                                        <EditorField
                                            editorState={values.editorState}
                                            disabledBlock={['header-one', 'header-two', 'unordered-list-item', 'ordered-list-item']}
                                            onChange={setFieldValue}
                                            onEditorStateChange={(nextEditorState) => this.onEditorStateChange(nextEditorState, setFieldValue)}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="editorState" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group radio">
                                    <label>Position du texte</label>
                                    <div className={touched.text_position && errors.text_position ? "field radio error" : "field radio"}>
                                        <Icon fontSize="small">picture_in_picture</Icon>
                                        <label>
                                            <span className="radio-input">
                                                <Field type="radio" name="text_position" value={TEXT_POSITION.TOP_LEFT} />
                                                <span className="radio-control"></span>
                                            </span>
                                            <span className="radio-label">En haut à gauche</span>
                                        </label>
                                        <label>
                                            <span className="radio-input">
                                                <Field type="radio" name="text_position" value={TEXT_POSITION.TOP_RIGHT} />
                                                <span className="radio-control"></span>
                                            </span>
                                            <span className="radio-label">En haut à droite</span>
                                        </label>
                                        <label>
                                            <span className="radio-input">
                                                <Field type="radio" name="text_position" value={TEXT_POSITION.BOTTOM_RIGHT} />
                                                <span className="radio-control"></span>
                                            </span>
                                            <span className="radio-label">En bas à droite</span>
                                        </label>
                                        <label>
                                            <span className="radio-input">
                                                <Field type="radio" name="text_position" value={TEXT_POSITION.BOTTOM_LEFT} />
                                                <span className="radio-control"></span>
                                            </span>
                                            <span className="radio-label">En bas à gauche</span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="text_position" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">add</Icon><span>Ajouter</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default CreateSlide;