import React, { createRef } from 'react';
import { Switch, Route, withRouter } from 'react-router-dom';

import Topbar from './topbar/Topbar';
import Sidebar from './sidebar/Sidebar';
import Bookings from './bookings/Bookings';
import Calendar from './calendar/Calendar';
import Reviews from './reviews/Reviews';
import Periods from './periods/Periods';
import Pricings from './pricings/Pricings';
import Page from './page/Page';
import Slideshow from './slideshow/Slideshow';
import Images from './images/Images';
import Users from './users/Users';
import Settings from './settings/Settings';
import Dashboard from './dashboard/Dashboard';
import NotFound from '../not_found/NotFound';
import JWTManager from '../../utils/JWTManager';
import PAGES from '../../constants/pages.enum';
import './Admin.scss';

class Admin extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            reduced_menu: window.innerWidth < 1024,
            menu_open: false
        };

        this.openMenuRef = createRef();
        this.smallMenuRef = createRef();
    }

    componentDidMount() {
        window.addEventListener('resize', this.updateDimensions);
        document.addEventListener('mousedown', this.onClickOutside);
        
        if (this.props.location.pathname === '/') this.props.onFullscreenChange(false);

        // Vérification de l'existence du JWT dans les cookies
        if (JWTManager.getToken() !== null && this.props.user === null) {
            this.loadUser();
        } else if (this.props.user === null || JWTManager.getToken() === null) {
            this.props.onPrevPathChange(this.props.history.location.pathname);
            this.props.history.push('/login');
        }
    }

    componentWillUnmount() {
        window.removeEventListener('resize', this.updateDimensions);
        document.removeEventListener('mousedown', this.onClickOutside);
    }

    updateDimensions = () => {
        if (window.innerWidth < 1024 && !this.state.reduced_menu) {
            this.setState({ 
                reduced_menu: true,
                menu_open: false
            });
        } else if (window.innerWidth >= 1024 && this.state.reduced_menu) {
            this.setState({
                reduced_menu: false,
                menu_open: false
            });
        }
    }

    onClickOutside = (e) => {
        if (
            this.state.menu_open && this.state.reduced_menu
            && this.smallMenuRef.current && !this.smallMenuRef.current.contains(e.target) 
            && this.openMenuRef.current && !this.openMenuRef.current.contains(e.target)
        ) this.setState({ menu_open: false });
    }

    loadUser = () => {
        // Régénération de l'utilisateur
        JWTManager.getRefreshedTokenWithUser().then((data) => {
            if (data) {
                this.props.onUserLogin(data.user, data.token, data.token_expires, data.refresh_token);
            } else {
                this.props.onPrevPathChange(this.props.history.location.pathname);
                this.props.history.push('/login');
            }
        });
    }

    onUserLogout = () => {
        this.props.onUserLogout();
        this.props.history.push('/login');
    }

    onMenuOpenChange = (menu_open) => {
        this.setState({ menu_open: menu_open });
    } 

    render() {
        return (
            <div className="Admin">
                <Topbar 
                    user={this.props.user}
                    onFullscreenChange={this.props.onFullscreenChange}
                    setMenuOpen={this.onMenuOpenChange}
                    reducedMenu={this.state.reduced_menu}
                    menuOpen={this.state.menu_open}
                    ref={this.openMenuRef}
                />
                <div className="page-container">
                    <Sidebar 
                        user={this.props.user}
                        onUserLogout={this.onUserLogout}
                        setMenuOpen={this.onMenuOpenChange}
                        reducedMenu={this.state.reduced_menu}
                        menuOpen={this.state.menu_open}
                        ref={this.smallMenuRef}
                    />
                    <div className="content">
                        <Switch>
                            <Route path="/reservations">
                                <Bookings
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/calendrier">
                                <Calendar
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/avis">
                                <Reviews
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/periodes">
                                <Periods
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/tarifs">
                                <Pricings 
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/accueil">
                                <Page 
                                    page_type={PAGES.HOME}
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/diaporama">
                                <Slideshow
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/le-chalet">
                                <Page 
                                    page_type={PAGES.CHALET}
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/activites">
                                <Page 
                                    page_type={PAGES.ACTIVITIES}
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/images">
                                <Images
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/mentions-legales">
                                <Page 
                                    page_type={PAGES.LEGAL_NOTICE}
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/cg-vente">
                                <Page 
                                    page_type={PAGES.TERMS_OF_SALES}
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/utilisateurs">
                                <Users 
                                    connected_user_id={this.props.user ? this.props.user._id : null}
                                    loadUser={this.loadUser}
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/parametres" exact>
                                <Settings 
                                    user={this.props.user} 
                                    loadUser={this.loadUser}
                                    onFullscreenChange={this.props.onFullscreenChange}
                                />
                            </Route>
                            <Route path="/" exact>
                                <Dashboard user={this.props.user} />
                            </Route>
                            <Route path="*">
                                <NotFound />
                            </Route>
                        </Switch>
                    </div>
                </div>    
            </div>
        );
    }
}

export default withRouter(Admin);