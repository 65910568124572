import React from 'react';
import Icon from '@mui/material/Icon';

import Notifications from './notifications/Notifications';
import './Topbar.scss';

class Topbar extends React.Component {
    render() {
        return (
            <div className="Topbar">
                <div className="left">
                    <img src="/logo/logo_simple.svg" alt="Logo 'Au Refuge des Chamois'" />
                    {
                        this.props.reducedMenu
                        && <button type="button" className={this.props.menuOpen ? "open-menu open" : "open-menu"} onClick={() => this.props.setMenuOpen(!this.props.menuOpen)} ref={this.props.innerRef}>
                        <Icon fontSize="medium">{this.props.menuOpen ? "close" : "menu"}</Icon>
                    </button>
                    }
                </div>
                <div className="right">
                    <div className="user">{this.props.user && 'Bonjour ' + this.props.user.firstname}</div>
                    <Notifications 
                        user={this.props.user} 
                        onFullscreenChange={this.props.onFullscreenChange}
                    />
                </div>
            </div>
        );
    }
}

export default React.forwardRef((props, ref) => <Topbar {...props} innerRef={ref} />);