import React from 'react';
import { ScrollLocky } from 'react-scroll-locky';
import { BrowserRouter as Router, Switch, Route } from 'react-router-dom';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';

import Login from './components/login/Login';
import Admin from './components/admin/Admin';
import JWTManager from './utils/JWTManager';
import './App.scss';

class App extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            user: null,
            prev_path: null,
            fullscreen: true
        }
    }

    componentDidMount() {
        // Gestion de l'écran de chargement
        const load_screen = document.getElementById('load-screen');
        if (load_screen) {
            setTimeout(() => load_screen.classList.add('loaded'), 3000);
            setTimeout(() => load_screen.outerHTML = '', 4000);
        }
    }

    onUserLogin = (user, token, token_expires, refresh_token) => {
        this.setState({
            user: user
        });
        return JWTManager.setToken(token, token_expires, refresh_token);
    }

    onUserLogout = () => {
        this.setState({
            user: null
        });
        JWTManager.eraseToken();
    }

    onPrevPathChange = (prev_path) => {
        if (prev_path !== this.state.prev_path) this.setState({ prev_path: prev_path });
    }

    onFullscreenChange = (fullscreen) => {
        if (fullscreen !== this.state.fullscreen) this.setState({ fullscreen: fullscreen });
    }

    render() {
        return (
            <ScrollLocky enabled={this.state.fullscreen} isolation={false} gapMode="padding" className="App">
                <Router>
                    <Switch>
                        <Route path="/login" exact>
                            <Login 
                                user={this.state.user}
                                prevPath={this.state.prev_path}
                                onUserLogin={(user, token, token_expires, refresh_token) => this.onUserLogin(user, token, token_expires, refresh_token)}
                                onFullscreenChange={this.onFullscreenChange}
                            />
                        </Route>
                        <Route path="/">
                            <Admin 
                                user={this.state.user}
                                prevPath={this.state.prev_path}
                                onUserLogin={(user, token, token_expires, refresh_token) => this.onUserLogin(user, token, token_expires, refresh_token)}
                                onUserLogout={this.onUserLogout}
                                onPrevPathChange={this.onPrevPathChange}
                                onFullscreenChange={this.onFullscreenChange}
                            />
                        </Route>
                    </Switch>
                </Router>
                <ToastContainer position={toast.POSITION.BOTTOM_RIGHT} />
            </ScrollLocky>
        );
    }
}

export default App;