import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import ImageUploadField from './ImageUploadField';
import JWTManager from '../../../../utils/JWTManager';
import './CreateImage.scss';

class CreateImage extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error_response: null
        }
    }

    onSubmit = (image) => {
        let data = new FormData();
        data.append('image', image.image);
        data.append('name', image.name);
        data.append('filename', image.filename);
        data.append('desc', image.desc);

        const id = toast.loading('Création de l\'image...', { toastId: 'create-image' });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/images/create',
            data: data
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Image créée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de créer l\'image',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="CreateImage">
                <Formik
                    initialValues={{ 
                        image: "",
                        name: "",
                        filename: "",
                        desc: ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            image: Yup.mixed()
                                .required("Image requise"),
                            name: Yup.string()
                                .required("Nom de l'image requis"),
                            filename: Yup.string()
                                .required("Nom du fichier requis"),
                            desc: Yup.string()
                        })
                    }
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched }) => (
                        <Form className="create-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <ImageUploadField />
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Nom de l'image*</label>
                                    <div className={touched.name && errors.name ? "field error" : "field"}>
                                        <Icon fontSize="small">image</Icon>
                                        <Field name="name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="name" />
                                </div>
                                <div className="form-group">
                                    <label>Nom du fichier*</label>
                                    <div className={touched.filename && errors.filename ? "field error" : "field"}>
                                        <Icon fontSize="small">upload_file</Icon>
                                        <Field name="filename" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="filename" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Description</label>
                                    <div className={touched.desc && errors.desc ? "field textarea error" : "field textarea"}>
                                        <Icon fontSize="small">article</Icon>
                                        <Field name="desc" as="textarea" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="desc" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">save</Icon><span>Enregistrer</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default CreateImage;