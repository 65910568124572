import React, { createRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import JWTManager from '../../../../utils/JWTManager';
import './EditUserSettings.scss';

class EditUserSettings extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error_response: null
        }

        this.editUserSettingsForm = createRef();
    }

    onSubmit = (userSettings) => {
        const id = toast.loading('Mise à jour des paramètres utilisateur...', { toastId: 'edit-user-settings-' + this.props.user._id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/users/' + this.props.user._id,
            data: userSettings
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Paramètres du utilisateur mis à jour avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.loadUser();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de mettre à jour les paramètres utilisateur',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="EditUserSettings">
                <Formik
                    initialValues={{ 
                        email: this.props.user ? this.props.user.email : "",
                        name: this.props.user ? this.props.user.name : "",
                        firstname: this.props.user ? this.props.user.firstname : "",
                        notif_email: this.props.user ? this.props.user.notif_email : false
                    }}
                    validationSchema={
                        Yup.object().shape({
                            email: Yup.string()
                                .email("Veuillez saisir une adresse mail")
                                .required("Adresse mail requise"),
                            name: Yup.string()
                                .required("Nom requis"),
                            firstname: Yup.string()
                                .required("Prénom requis"),
                            notif_email: Yup.boolean()
                        })
                    }
                    innerRef={this.editUserSettingsForm}
                    enableReinitialize={true}
                    onSubmit={(userSettings) => this.onSubmit(userSettings)}
                >
                    {({ errors, touched, values }) => (
                        <Form className="user-settings-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Mail*</label>
                                    <div className={touched.email && errors.email ? "field error" : "field"}>
                                        <Icon fontSize="small">alternate_email</Icon>
                                        <Field name="email" autoComplete="email" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="email" />
                                </div>
                                <div className="form-group">
                                    <label>Nom*</label>
                                    <div className={touched.name && errors.name ? "field error" : "field"}>
                                        <Icon fontSize="small">face</Icon>
                                        <Field name="name" autoComplete="family-name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="name" />
                                </div>
                                <div className="form-group">
                                    <label>Prénom*</label>
                                    <div className={touched.firstname && errors.firstname ? "field error" : "field"}>
                                        <Icon fontSize="small">face</Icon>
                                        <Field name="firstname" autoComplete="given-name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="firstname" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <div className={touched.notif_email && errors.notif_email ? "field checkbox notif error" : "field checkbox notif"}>
                                        <label>
                                            <span className="checkbox-input">
                                                <span className="checkbox-label">Notifications par mail</span>
                                                <Field type="checkbox" name="notif_email" checked={values.notif_email} />
                                                <Icon fontSize="tiny">{values.notif_email ? "notifications_active" : "notifications_off"}</Icon>
                                            </span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="notif_mail" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default EditUserSettings;