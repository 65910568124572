import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import JWTManager from '../../../../utils/JWTManager';
import './CreatePricing.scss';

class CreatePricing extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error_response: null
        }
    }

    onSubmit = (pricing) => {
        const id = toast.loading('Création du tarif...', { toastId: 'create-pricing' });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/pricings/create',
            data: {
                name: pricing.name,
                week_pricing: pricing.week_pricing ? Number(String(pricing.week_pricing).replace(/,/, '.')) : null,
                weekend_pricing: pricing.weekend_pricing ? Number(String(pricing.weekend_pricing).replace(/,/, '.')) : null,
                week_only: pricing.week_only,
                week_reduction: pricing.week_reduction ? Number(String(pricing.week_reduction).replace(/,/, '.')) : null,
                week_special: pricing.week_special
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Tarif créé avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de créer le tarif',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="CreatePricing">
                <Formik
                    initialValues={{
                        name: "", 
                        week_pricing: "",
                        weekend_pricing: "",
                        week_only: false,
                        week_reduction: "",
                        week_special: false
                    }}
                    validationSchema={
                        Yup.object().shape({
                            name: Yup.string()
                                .required("Nom du tarif requis"),
                            week_pricing: Yup.number("Veuillez saisir un montant")
                                .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un montant")
                                .positive("Veuillez saisir un montant positif")
                                .required("Tarif semaine requis"),
                            weekend_pricing: Yup.number("Veuillez saisir un montant")
                                .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un montant")
                                .positive("Veuillez saisir un montant positif")
                                .required("Tarif week-end requis"),
                            week_only: Yup.boolean(),
                            week_reduction: Yup.number("Veuillez saisir un pourcentage")
                                .transform((_value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un pourcentage")
                                .positive("Veuillez saisir un pourcentage positif")
                                .max(100, "Veuillez saisir un pourcentage inférieur à 100"),
                            week_special: Yup.boolean()
                        })
                    }
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched }) => (
                        <Form className="create-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Nom du tarif*</label>
                                    <div className={touched.name && errors.name ? "field error" : "field"}>
                                        <Icon fontSize="small">payment</Icon>
                                        <Field name="name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="name" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Tarif semaine*</label>
                                    <div className={touched.week_pricing && errors.week_pricing ? "field error" : "field"}>
                                        <Icon fontSize="small">price_change</Icon>
                                        <Field name="week_pricing" />
                                        <span className="field-addon">&euro;</span>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="week_pricing" />
                                </div>
                                <div className="form-group">
                                    <label>Tarif week-end*</label>
                                    <div className={touched.weekend_pricing && errors.weekend_pricing ? "field error" : "field"}>
                                        <Icon fontSize="small">price_change</Icon>
                                        <Field name="weekend_pricing" />
                                        <span className="field-addon">&euro;</span>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="weekend_pricing" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group checkbox">
                                    <div className={touched.week_only && errors.week_only ? "field checkbox error" : "field checkbox"}>
                                        <label>
                                            <span className="checkbox-input">
                                                <span className="checkbox-label">Semaine uniquement</span>
                                                <Field type="checkbox" name="week_only" />
                                                <span className="checkbox-control"><Icon fontSize="tiny">checked</Icon></span>
                                            </span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="week_only" />
                                </div>
                                <div className="form-group">
                                    <label>Réduction semaine</label>
                                    <div className={touched.week_reduction && errors.week_reduction ? "field error" : "field"}>
                                        <Icon fontSize="small">local_offer</Icon>
                                        <Field name="week_reduction" />
                                        <span className="field-addon">%</span>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="week_reduction" />
                                </div>
                                <div className="form-group checkbox">
                                    <div className={touched.week_special && errors.week_special ? "field checkbox error" : "field checkbox"}>
                                        <label>
                                            <span className="checkbox-input">
                                                <span className="checkbox-label">Semaine spéciale</span>
                                                <Field type="checkbox" name="week_special" />
                                                <span className="checkbox-control"><Icon fontSize="tiny">checked</Icon></span>
                                            </span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="week_special" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">save</Icon><span>Enregistrer</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default CreatePricing;