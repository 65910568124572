import Cookies from "universal-cookie/es6";
import axios from "axios";

const JWTManager = () => {
    let refreshTimeOut;
    const cookies = new Cookies();

    const refreshToken = (delay) => {
        refreshTimeOut = window.setTimeout(
            getRefreshedToken,
            (delay - 10) * 1000
        );
    }

    const abortRefreshToken = () => {
        if (refreshTimeOut) window.clearTimeout(refreshTimeOut);
    }

    const getRefreshedToken = () => {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/refresh-token',
            data: {
                token: getToken(),
                refresh_token: getRefreshToken()
            }
        }).then((response) => {
            if (response.data) {
                if (response.data.token) {
                    setToken(response.data.token, response.data.token_expires, response.data.refresh_token);
                    return true;
                }
                return false;
            }
        }).catch((error) => {
            eraseToken();
            global.console.log('Erreur dans le renouvellement du JWT.');
            return false;
        }).then((response) => response.data);
    }

    const getRefreshedTokenWithUser = () => {
        return axios({
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/refresh-token-with-user',
            data: {
                token: getToken(),
                refresh_token: getRefreshToken()
            }
        }).then((response) => {
            if (response.data) {
                if (response.data.token) {
                    setToken(response.data.token, response.data.token_expires, response.data.refresh_token);
                    return response.data;
                }
                return false;
            }
        }).catch((error) => {
            eraseToken();
            global.console.log('Erreur dans le renouvellement du JWT.');
            return false;
        });
    }

    const getHeader = () => {
        return 'Bearer ' + getToken();
    }

    const getToken = () => {
        return cookies.get('token') || null;
    };

    const getRefreshToken = () => {
        return cookies.get('refresh_token') || null;
    }

    const setToken = (token, token_expires, refresh_token) => {
        cookies.set('token', token, { path: '/', sameSite: 'lax' });
        cookies.set('refresh_token', refresh_token, { path: '/', sameSite: 'lax' });
        refreshToken(token_expires);
        return true;
    }

    const eraseToken = () => {
        cookies.remove('token');
        cookies.remove('refresh_token');
        abortRefreshToken();
        return true;
    }

    return {
        eraseToken,
        getHeader,
        getToken,
        getRefreshedTokenWithUser,
        setToken
    }
}

export default JWTManager();