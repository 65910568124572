import React, { createRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import JWTManager from '../../../../utils/JWTManager';
import './ChangePassword.scss';

class ChangePassword extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            error_response: null
        }

        this.changePasswordForm = createRef();
    }

    onSubmit = (changePassword) => {
        const id = toast.loading('Mise à jour du mot de passe...', { toastId: 'change-password-' + this.props.user._id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/users/' + this.props.user._id + '/change-password',
            data: changePassword
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Mot de passe mis à jour avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de mettre à jour le mot de passe',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="ChangePassword">
                <Formik
                    initialValues={{ 
                        old_password: "",
                        new_password: "",
                        new_password_confirm: ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            old_password: Yup.string()
                                .required("Ancien mot de passe requis"),
                            new_password: Yup.string()
                                .required("Nouveau mot de passe requis"),
                            new_password_confirm: Yup.string()
                                .oneOf([Yup.ref('new_password'), null], "Confirmation incorrecte")
                                .required("Confirmation du mot de passe requise")
                        })
                    }
                    innerRef={this.changePasswordForm}
                    onSubmit={(changePassword) => this.onSubmit(changePassword)}
                >
                    {({ errors, touched }) => (
                        <Form className="change-password-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Ancien mot de passe*</label>
                                    <div className={touched.old_password && errors.old_password ? "field error" : "field"}>
                                        <Icon fontSize="small">password</Icon>
                                        <Field name="old_password" type="password" autoComplete="current-password" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="old_password" />
                                </div>
                                <div className="form-group">
                                    <label>Nouveau mot de passe*</label>
                                    <div className={touched.new_password && errors.new_password ? "field error" : "field"}>
                                        <Icon fontSize="small">password</Icon>
                                        <Field name="new_password" type="password" autoComplete="new-password" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="new_password" />
                                </div>
                                <div className="form-group">
                                    <label>Confirmation du mot de passe*</label>
                                    <div className={touched.new_password_confirm && errors.new_password_confirm ? "field error" : "field"}>
                                        <Icon fontSize="small">password</Icon>
                                        <Field name="new_password_confirm" type="password" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="new_password_confirm" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Modifier le mot de passe</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default ChangePassword;