import React from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import EditorField from '../../../../utils/editor_field/EditorField';
import SelectField from '../../../../utils/SelectField';
import FormatManager from '../../../../utils/FormatManager';
import JWTManager from '../../../../utils/JWTManager';
import './EditInfo.scss';

class EditInfo extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            images: null,
            images_options: null,
            initial_option: null,
            error_response: null,
            editor_state: FormatManager.htmlToEditor()
        }
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null) this.loadData();
    }

    loadData = () => {
        // Récupération des images
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/images'
        }).then((response) => {
            if (!response) {
                this.setState({
                    images: null,
                    images_options: null,
                    initial_option: null,
                    editor_state: FormatManager.htmlToEditor()
                });
            }
            if (response.data) {
                this.setState({
                    images: response.data,
                    images_options: this.getImagesOptions(response.data)
                }, () => this.setState({
                    initial_option: this.getInitialOption(),
                    editor_state: FormatManager.htmlToEditor(this.props.page.desc)
                }));
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getInitialOption = () => {
        if (this.state.images_options && this.props.page && this.props.page.header_image) return this.state.images_options.filter((option) => option.value === this.props.page.header_image._id);
        return null;
    }

    getImagesOptions = (images) => {
        return images.map(image => ({
            value: image.id,
            label: <div className='image-option'>
                <img 
                    srcSet={FormatManager.imageSrcSet(image.paths)}
                    sizes="6rem"
                    src={image.paths.w1920}
                    alt = ''     
                />
                <span>{image.name}</span>
            </div>
        }));
    }

    imagesFilter = (option, searchText) => {
        return option.data.label.props.children[1].props.children.toLowerCase().includes(searchText.toLowerCase());
    }

    onEditorStateChange = (editorState, setFieldValue) => {
        setFieldValue('editorState', editorState);
    }

    onSubmit = (page) => {
        const id = toast.loading('Mise à jour des informations...', { toastId: 'edit-info-' + this.props.page.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'patch',
            url: process.env.REACT_APP_API_URL + '/auth/pages/' + this.props.page.id,
            data: {
                title: page.title,
                desc: FormatManager.editorToHtml(page.editorState),
                header_image: page.header_image.value,
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Informations mises à jour avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de mettre à jour les informations',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="EditInfo">
                <Formik
                    initialValues={{
                        title: this.props.page ? this.props.page.title : "",
                        editorState: this.state.editor_state,
                        header_image: this.state.initial_option
                    }}
                    validationSchema={
                        Yup.object().shape({
                            title: Yup.string()
                                .required("Titre de la page requis"),
                            editorState: Yup.object(),
                            header_image: Yup.mixed()
                                .required("Image d'entête requise")
                        })
                    }
                    enableReinitialize={true}
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="edit-form">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Titre*</label>
                                    <div className={touched.title && errors.title ? "field error" : "field"}>
                                        <Icon fontSize="small">title</Icon>
                                        <Field name="title" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="title" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Description</label>
                                    <div className={touched.editorState && errors.editorState ? "field editor error" : "field editor"}>
                                        <Icon fontSize="small">description</Icon>
                                        <EditorField
                                            editorState={values.editorState}
                                            onChange={setFieldValue}
                                            onEditorStateChange={(nextEditorState) => this.onEditorStateChange(nextEditorState, setFieldValue)}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="editorState" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Image d'entête*</label>
                                    <div className={touched.header_image && errors.header_image ? "field error" : "field"}>
                                        <Icon fontSize="small">image</Icon>
                                        <SelectField
                                            className="select-image"
                                            name="header_image"
                                            isClearable
                                            options={this.state.images_options}
                                            filterOption={this.imagesFilter}
                                            placeholder="Choisir une image..."
                                            noOptionsMessage={() => "Aucune image"}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="header_image" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">update</Icon><span>Mettre à jour</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default EditInfo;