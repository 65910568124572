import React from 'react';
import Icon from '@mui/material/Icon';

import './Overlay.scss';

class Overlay extends React.Component {
    componentDidMount() {
        document.addEventListener('keydown', this.onEscClick, false);
    }

    componentWillUnmount() {
        document.removeEventListener('keydown', this.onEscClick, false);
    }

    getClassName = () => {
        return this.props.show_overlay ? "Overlay" : "Overlay hidden";
    }

    onEscClick = (e) => {
        if (e.keyCode === 27) this.props.onClose();
    }

    render() {
        return (
            <div className={this.getClassName()}>
                <div className="overlay-block">
                    <button onClick={() => this.props.onClose()} className="close-button"><Icon fontSize="small">close</Icon></button>
                    <h3>{this.props.title}</h3>
                    {this.props.children}
                </div>
            </div>
        );
    }
}

export default Overlay;