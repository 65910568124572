import React from 'react';
import { useTable, useSortBy, usePagination, useGlobalFilter, useAsyncDebounce } from 'react-table';
import Select from 'react-select';
import Icon from '@mui/material/Icon';

import './Table.scss';

function GlobalFilter({
    globalFilter,
    setGlobalFilter,
}) {
    const [value, setValue] = React.useState(globalFilter)
    const onChange = useAsyncDebounce(value => {
        setGlobalFilter(value || undefined);
    }, 200)
  
    return (
        <div className="global-filter">
            <Icon fontSize="small">search</Icon>
            <input
                placeholder="Rechercher..."
                value={value || ""}
                onChange={(e) => {
                    setValue(e.target.value);
                    onChange(e.target.value);
                }}
            />
        </div>
    );
}

const defaultPropGetter = () => ({})

function Table(props) {
    const data = React.useMemo(
        () => props.data, [props.data]
    );

    const columns = React.useMemo(
        () => props.columns, [props.columns]
    );

    const {
        getTableProps,
        getTableBodyProps,
        getHeaderProps = defaultPropGetter,
        getColumnProps = defaultPropGetter,
        getRowProps = defaultPropGetter,
        getCellProps = defaultPropGetter,
        headerGroups,
        prepareRow,
        page,
        canPreviousPage,
        canNextPage,
        pageOptions,
        pageCount,
        gotoPage,
        nextPage,
        previousPage,
        setPageSize,
        preGlobalFilteredRows,
        setGlobalFilter,
        state,
        state: { pageIndex, pageSize },
    } = useTable({ 
        columns, 
        data,
        initialState: { pageIndex: 0 , pageSize: props.pageSize ?? 10 },
        autoResetPage: false
    }, useGlobalFilter, useSortBy, usePagination);

    return (
        <div className="Table">
            <div className="table-tools">
                <div className="pagination">
                    <div className="pagination-control">
                        <Select
                            className="select-container"
                            classNamePrefix="select"
                            defaultValue={{ value: pageSize, label: pageSize }}
                            onChange={e => {
                                setPageSize(Number(e.value))
                            }}
                            options={[
                                { value: 5, label: 5 },
                                { value: 10, label: 10 },
                                { value: 25, label: 25 },
                                { value: 50, label: 50 }
                            ]}
                        />
                        <span>Page <strong>{pageIndex + 1} sur {pageOptions.length}</strong></span>
                    </div>
                    <div className="navigation-buttons">
                        <button onClick={() => gotoPage(0)} disabled={!canPreviousPage}>
                            <Icon fontSize="small" className={!canPreviousPage ? "inactive" : ""}>first_page</Icon>
                        </button>
                        <button onClick={() => previousPage()} disabled={!canPreviousPage}>
                            <Icon fontSize="small" className={!canPreviousPage ? "inactive" : ""}>navigate_before</Icon>
                        </button>
                        <button onClick={() => nextPage()} disabled={!canNextPage}>
                            <Icon fontSize="small" className={!canNextPage ? "inactive" : ""}>navigate_next</Icon>
                        </button>
                        <button onClick={() => gotoPage(pageCount - 1)} disabled={!canNextPage}>
                            <Icon fontSize="small" className={!canNextPage ? "inactive" : ""}>last_page</Icon>
                        </button>
                    </div>
                </div>
                <GlobalFilter
                    preGlobalFilteredRows={preGlobalFilteredRows}
                    globalFilter={state.globalFilter}
                    setGlobalFilter={setGlobalFilter}
                />
            </div>
            <div className="table-container">
                <table {...getTableProps()}>
                    <thead>
                        {headerGroups.map((headerGroup) => (
                            <tr {...headerGroup.getHeaderGroupProps()}>
                                {headerGroup.headers.map((column) => (
                                    <th {...column.getHeaderProps([
                                        {
                                        className: column.className
                                        },
                                        getColumnProps(column),
                                        getHeaderProps(column),
                                        column.getSortByToggleProps({ title: undefined })
                                    ])}>
                                        <div className="header">
                                            <div className="header-title">
                                                {column.render("Header")}
                                            </div>
                                            {
                                                column.canSort
                                                && <div className="header-sort">
                                                    <Icon fontSize="tiny" className={(column.isSorted && !column.isSortedDesc) || !column.isSorted ? "inactive" : ""}>arrow_drop_up</Icon>
                                                    <Icon fontSize="tiny" className={(column.isSorted && column.isSortedDesc) || !column.isSorted ? "inactive" : ""}>arrow_drop_down</Icon>
                                                </div>
                                            }
                                        </div>
                                    </th>
                                ))}
                            </tr>
                        ))}
                    </thead>
                    <tbody {...getTableBodyProps()}>
                        {(page.length > 0 && page.map((row, i) => {
                            prepareRow(row);
                            return (
                                <tr {...row.getRowProps(getRowProps(row))}>
                                    {row.cells.map((cell) => {
                                        return (
                                            <td {...cell.getCellProps([
                                                {
                                                className: cell.column.className
                                                },
                                                getColumnProps(cell.column),
                                                getCellProps(cell),
                                            ])}>
                                                {cell.render("Cell")}
                                            </td>
                                        )
                                    })}
                                </tr>
                            );
                        })) || (<tr><td className="no-result" colSpan={columns.length}>Aucun résultat</td></tr>)}
                    </tbody>
                </table>
            </div>
        </div>
    );
}

export default Table;