import React from 'react';
import ReactDOM from 'react-dom';
import './index.css';
import App from './App';
import reportWebVitals from './reportWebVitals';

import './fonts/MaterialIconsRound-Regular.otf';
import './fonts/noto_sans/NotoSans-Regular.ttf';
import './fonts/noto_sans/NotoSans-Bold.ttf';
import './fonts/noto_sans/NotoSans-BoldItalic.ttf';
import './fonts/noto_sans/NotoSans-Italic.ttf';
import './fonts/roboto/Roboto-Regular.ttf';
import './fonts/roboto/Roboto-Bold.ttf';
import './fonts/roboto/Roboto-BoldItalic.ttf';
import './fonts/roboto/Roboto-Italic.ttf';
import './fonts/roboto/Roboto-Light.ttf';
import './fonts/roboto/Roboto-LightItalic.ttf';
import './fonts/roboto/Roboto-Medium.ttf';
import './fonts/roboto/Roboto-MediumItalic.ttf';
import './fonts/roboto/Roboto-Thin.ttf';
import './fonts/roboto/Roboto-ThinItalic.ttf';

ReactDOM.render(
  <React.StrictMode>
    <App />
  </React.StrictMode>,
  document.getElementById('root')
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
