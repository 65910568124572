import React from 'react';
import { Switch, Route, Link, withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { animateScroll } from 'react-scroll';
import Icon from '@mui/material/Icon';

import Table from '../table/Table';
import Confirmation from '../confirmation/Confirmation';
import Overlay from '../overlay/Overlay';
import EditPeriod from './edit_period/EditPeriod';
import CreatePeriod from './create_period/CreatePeriod';
import NotFound from '../../not_found/NotFound';
import JWTManager from '../../../utils/JWTManager';
import FormatManager from '../../../utils/FormatManager';
import './Periods.scss';

class Periods extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            hidden: true,
            periods: null,
            show_delete: false,
            period_delete_id: null,
            show_edit: false,
            show_create: false
        }
    }

    componentDidMount() {
        this.checkPath();
        setTimeout(() => this.setState({ hidden: false }), 1);
        if (JWTManager.getToken() !== null) this.loadData();
        animateScroll.scrollTo(0, {
            duration: 0
        });
    }

    componentDidUpdate(prevProps) {
        if (prevProps.location.pathname !== this.props.location.pathname) this.checkPath();
    }

    checkPath = () => {
        this.setState({ 
            show_delete: this.props.location.pathname.includes('/delete'),
            show_edit: this.props.location.pathname.includes('/edit'),
            show_create: this.props.location.pathname.includes('/create')
        });
        this.props.onFullscreenChange(
            this.props.location.pathname.includes('/create') ||
            this.props.location.pathname.includes('/edit') ||
            this.props.location.pathname.includes('/delete')
        );
    }

    loadData = () => {
        // Récupération des périodes
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/periods'
        }).then((response) => {
            if (!response) {
                this.setState({
                    periods: null
                });
            }

            if (response.data) {
                this.setState({
                    periods: response.data
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    getColumns = () => {
        return [
            {
                Header: 'Début de période',
                accessor: 'start_period',
                className: 'main',
                Cell: tableProps => FormatManager.dateLocaleFormat(tableProps.row.original.start_period)
            }, {
                Header: 'Fin de période',
                accessor: 'end_period',
                className: 'main',
                Cell: tableProps => FormatManager.dateLocaleFormat(tableProps.row.original.end_period)
            }, {
                Header: 'Tarif',
                accessor: 'pricing.name',
                className: 'main',
                Cell: tableProps => <Link to={"/tarifs/" + tableProps.row.original.pricing._id + "/edit"}>{tableProps.row.original.pricing.name}</Link>
            }, {
                Header: 'Tarif semaine',
                accessor: 'pricing.week_pricing',
                Cell: tableProps => FormatManager.priceFormat(tableProps.row.original.pricing.week_pricing)
            }, {
                Header: 'Tarif week-end',
                accessor: 'pricing.weekend_pricing',
                Cell: tableProps => FormatManager.priceFormat(tableProps.row.original.pricing.weekend_pricing)
            }, {
                Header: 'Semaine uniquement',
                accessor: 'pricing.week_only',
                className: 'center',
                sortType: (rowA, rowB, id, desc) => {
                    if (rowA.original[id] && !rowB.original[id]) return 1;
                    if (!rowA.original[id] && rowB.original[id]) return -1;
                    return 0;
                },
                Cell: tableProps => tableProps.row.original.pricing.week_only ? <Icon fontSize="tiny">check</Icon> : null
            }, {
                Header: 'Réduction semaine',
                accessor: 'pricing.week_reduction',
                Cell: tableProps => tableProps.row.original.pricing.week_reduction ? tableProps.row.original.pricing.week_reduction + ' %' : null
            }, {
                Header: 'Semaine spéciale',
                accessor: 'pricing.week_special',
                className: 'center',
                sortType: (rowA, rowB, id, desc) => {
                    if (rowA.original[id] && !rowB.original[id]) return 1;
                    if (!rowA.original[id] && rowB.original[id]) return -1;
                    return 0;
                },
                Cell: tableProps => tableProps.row.original.pricing.week_special ? <Icon fontSize="tiny">check</Icon> : null
            }, {
                Header: '',
                id: 'edit-column',
                disableSortBy: true,
                className: 'actions',
                Cell: tableProps => <div className="action-buttons">
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/edit"} className="edit-button">
                        <Icon fontSize="tiny">edit</Icon><span>Modifier</span>
                    </Link>
                    <Link to={this.props.match.url + "/" + tableProps.row.original.id + "/delete"} className="delete-button">
                        <Icon fontSize="tiny">delete</Icon><span>Supprimer</span>
                    </Link>
                </div>
            }
        ];
    }

    onDeleteOpen = (row) => {
        this.setState({
            show_delete: true,
            period_delete_id: row
        });
    }

    onDeleteAnnulation = () => {
        this.setState({
            show_delete: false,
            period_delete_id: null
        });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onDeleteConfirmation = () => {
        // Suppression de la période
        const id = toast.loading('Suppression de la période...', { toastId: 'delete-period-' + this.state.period_delete_id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'delete',
            url: process.env.REACT_APP_API_URL + '/auth/periods/' + this.state.period_delete_id,
        }).then((response) => {
            if (response.data.success) {
                this.setState({
                    show_delete: false,
                    period_delete_id: null
                });
                this.props.onFullscreenChange(false);
                toast.update(id, {
                    render: 'Période supprimée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.loadData();
                setTimeout(() => this.props.history.push(this.props.match.url), 400);
            }
        }).catch((error) => {
            console.error(error.response);
            toast.update(id, {
                render: 'Impossible de supprimer la période',
                type: toast.TYPE.ERROR,
                isLoading: false,
                autoClose: 5000
            });
        });
    }

    onEditClose = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onEditSubmit = () => {
        this.setState({ show_edit: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateClose = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    onCreateSubmit = () => {
        this.setState({ show_create: false });
        this.props.onFullscreenChange(false);
        this.loadData();
        setTimeout(() => this.props.history.push(this.props.match.url), 400);
    }

    render() {
        return (
            <div className={this.state.hidden ? "Periods hidden" : "Periods"}>
                <h2 className={this.state.hidden ? "hidden" : ""}>Périodes</h2>
                <div className="periods-content">
                    <div className="periods-list">
                        <div className="block-heading">
                            <h3><Icon fontSize="small">calendar_view_month</Icon><span>Toutes les périodes</span></h3>
                            <Link to={this.props.match.url + "/create"} className="create-button">
                                <Icon fontSize="small">more_time</Icon><span>Ajouter une période</span>
                            </Link>
                        </div>
                        <div className="block-content">
                            {
                                (this.state.periods === null || this.state.periods.length === 0)
                                && <p>Aucune période</p>
                            }
                            {
                                this.state.periods && this.state.periods.length > 0
                                && <Table data={this.state.periods} columns={this.getColumns()} pageSize={10} />
                            }
                        </div>
                    </div>
                </div>
                <Switch>
                    <Route path={this.props.match.path + "/:periodId/edit"} exact>
                        <Overlay
                            title="Modification de la période"
                            onClose={this.onEditClose}
                            show_overlay={this.state.show_edit}
                        >
                            <EditPeriod
                                onSubmit={this.onEditSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/:elementId/delete"} exact>
                        <Confirmation
                            title="Voulez-vous supprimer cette période ?"
                            onOpen={this.onDeleteOpen}
                            onCancel={this.onDeleteAnnulation}
                            onConfirm={this.onDeleteConfirmation}
                            show_confirmation={this.state.show_delete}
                        />
                    </Route>
                    <Route path={this.props.match.path + "/create"} exact>
                        <Overlay 
                            title="Nouvelle période"
                            onClose={this.onCreateClose}
                            show_overlay={this.state.show_create}
                        >
                            <CreatePeriod
                                onSubmit={this.onCreateSubmit}
                            />
                        </Overlay>
                    </Route>
                    <Route path={this.props.match.path + "/*"}>
                        <NotFound />
                    </Route>
                </Switch>
            </div>
        );
    }
}

export default withRouter(Periods);