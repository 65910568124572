import React, { createRef } from 'react';
import { withRouter } from 'react-router-dom';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import Icon from '@mui/material/Icon';

import EditorField from '../../../../utils/editor_field/EditorField';
import JWTManager from '../../../../utils/JWTManager';
import FormatManager from '../../../../utils/FormatManager';
import './CreateComment.scss';

class CreateComment extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            editor_state: FormatManager.htmlToEditor(),
            review: null,
            review_state: FormatManager.htmlToEditor(),
            error_response: null
        }

        this.createCommentForm = createRef();
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null && this.props.match.params.reviewId) this.loadData();
    }

    loadData = () => {
        // Récupération de l'avis
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/reviews/' + this.props.match.params.reviewId
        }).then((response) => {
            if (!response) {
                this.setState({
                    review: null,
                    review_state: FormatManager.htmlToEditor()
                });
            }

            if (response.data) {
                this.setState({
                    review: response.data,
                    review_state: FormatManager.htmlToEditor(response.data.review)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(error.response.data.message);
        });
    }

    onEditorStateChange = (editorState, setFieldValue) => {
        setFieldValue('editorState', editorState);
    }

    onSubmit = (comment) => {
        const id = toast.loading('Création du commentaire...', { toastId: 'create-comment-' + this.state.review.id });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/comments/create',
            data: {
                comment: FormatManager.editorToHtml(comment.editorState),
                review: this.state.review.id
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null
                });
                toast.update(id, {
                    render: 'Commentaire créé avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de créer le commentaire',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    render() {
        return (
            <div className="CreateComment">
                <Formik
                    initialValues={{
                        customer: this.state.review ? this.state.review.name.toUpperCase() + ' ' + this.state.review.firstname : "",
                        email: this.state.review ? this.state.review.email : "",
                        date: this.state.review ? FormatManager.dateTimeLocaleFormat(this.state.review.date) : "",
                        note: this.state.review ? this.state.review.note : "",
                        review: this.state.review_state,
                        editorState: this.state.editor_state
                    }}
                    validationSchema={
                        Yup.object().shape({
                            editorState: Yup.object()
                        })
                    }
                    enableReinitialize={true}
                    innerRef={this.createCommentForm}
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched, values, setFieldValue }) => (
                        <Form className="create-form" autoComplete="off">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Locataire</label>
                                    <div className="field">
                                        <Icon fontSize="small">face</Icon>
                                        <Field name="customer" readOnly />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Mail</label>
                                    <div className="field">
                                        <Icon fontSize="small">alternate_email</Icon>
                                        <Field name="email" readOnly />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Date</label>
                                    <div className="field">
                                        <Icon fontSize="small">today</Icon>
                                        <Field name="date" readOnly />
                                    </div>
                                </div>
                                <div className="form-group">
                                    <label>Note</label>
                                    <div className="field">
                                        <div className="stars-container">
                                            {
                                                values.note >= 1 
                                                    ? <Icon fontSize="tiny">star_rate</Icon>
                                                    : values.note === 0.5
                                                        ? <Icon fontSize="tiny">star_half</Icon>
                                                        : <Icon fontSize="tiny">star_border</Icon>
                                            }
                                            {
                                                values.note >= 2
                                                    ? <Icon fontSize="tiny">star_rate</Icon>
                                                    : values.note === 1.5
                                                        ? <Icon fontSize="tiny">star_half</Icon>
                                                        : <Icon fontSize="tiny">star_border</Icon>
                                            }
                                            {
                                                values.note >= 3 
                                                    ? <Icon fontSize="tiny">star_rate</Icon>
                                                    : values.note === 2.5
                                                        ? <Icon fontSize="tiny">star_half</Icon>
                                                        : <Icon fontSize="tiny">star_border</Icon>
                                            }
                                            {
                                                values.note >= 4
                                                    ? <Icon fontSize="tiny">star_rate</Icon>
                                                    : values.note === 3.5
                                                        ? <Icon fontSize="tiny">star_half</Icon>
                                                        : <Icon fontSize="tiny">star_border</Icon>
                                            }
                                            {
                                                values.note >= 5
                                                    ? <Icon fontSize="tiny">star_rate</Icon>
                                                    : values.note === 4.5
                                                        ? <Icon fontSize="tiny">star_half</Icon>
                                                        : <Icon fontSize="tiny">star_border</Icon>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Avis</label>
                                    <div className="field textarea">
                                        <Icon fontSize="small">rate_review</Icon>
                                        <EditorField
                                            editorState={values.review}
                                            readOnly={true}
                                        />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Commentaire</label>
                                    <div className={touched.editorState && errors.editorState ? "field editor error" : "field editor"}>
                                        <Icon fontSize="small">mode_comment</Icon>
                                        <EditorField
                                            editorState={values.editorState}
                                            disabledBlock={['header-one', 'header-two', 'header-three', 'header-four', 'header-five', 'header-six']}
                                            onChange={setFieldValue}
                                            onEditorStateChange={(nextEditorState) => this.onEditorStateChange(nextEditorState, setFieldValue)}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="editorState" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">save</Icon><span>Enregistrer</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default withRouter(CreateComment);