import React, { createRef } from 'react';
import axios from 'axios';
import { toast } from 'react-toastify';
import { Formik, Form, Field, ErrorMessage } from 'formik';
import * as Yup from 'yup';
import 'yup-phone';
import Icon from '@mui/material/Icon';
import moment from 'moment';

import DatePickerField from '../../../../utils/date_picker_field/DatePickerField';
import PhoneInputField from '../../../../utils/PhoneInputField';
import JWTManager from '../../../../utils/JWTManager';
import FormatManager from '../../../../utils/FormatManager';
import './CreateBooking.scss';

class CreateBooking extends React.Component {
    constructor(props) {
        super(props);

        this.state = {
            inavailable_dates: null,
            error_response: null,
            in_date: null,
            out_date: null,
            menage: false
        }

        this.outDateCreate = createRef();
    }

    componentDidMount() {
        if (JWTManager.getToken() !== null) this.getInavailableDates();
    }

    getInavailableDates = () => {
        // Récupération des dates non disponibles
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'get',
            url: process.env.REACT_APP_API_URL + '/auth/bookings/inavailable-dates'
        }).then((response) => {
            if (!response) {
                this.setState({
                    inavailable_dates: null
                });
            }
            if (response.data) {
                this.setState({
                    inavailable_dates: FormatManager.datesArray(response.data.inavailableDates)
                });
            }
        }).catch((error) => {
            console.log(error.response);
            if (error.response.data) toast.error(JSON.stringify(error.response.data));
        });
    }

    calculatePrice = (setFieldValue) => {
        if (this.state.in_date && this.state.out_date) {
            // Calcul du prix de la réservation
            axios({
                headers: {
                    'Authorization': JWTManager.getHeader()
                },
                method: 'get',
                url: process.env.REACT_APP_API_URL + '/auth/bookings/price/' + this.state.in_date + '/' + this.state.out_date + '/none' + (this.state.menage ? '/menage' : ''),
            }).then((response) => {
                if (response.data) {
                    setFieldValue('price', response.data.price);
                    this.setState({
                        error_response: null
                    });
                }
            }).catch((error) => {
                if (error.response.data) {
                    this.setState({
                        error_response: error.response.data.message
                    });
                } else {
                    console.error(error.response);
                }
            });
        }
    }

    onCalculateClick = (e, setFieldValue) => {
        e.preventDefault();
        this.calculatePrice(setFieldValue);
    }

    onSubmit = (booking) => {
        const id = toast.loading('Création de la réservation...', { toastId: 'create-booking' });
        axios({
            headers: {
                'Authorization': JWTManager.getHeader()
            },
            method: 'post',
            url: process.env.REACT_APP_API_URL + '/auth/bookings/create',
            data: {
                name: booking.name,
                firstname: booking.firstname,
                in_date: FormatManager.dateDBFormat(booking.in_date),
                out_date: FormatManager.dateDBFormat(booking.out_date),
                address: booking.address,
                postal_code: booking.postal_code,
                city: booking.city,
                country: booking.country,
                email: booking.email,
                phone: booking.phone,
                nb_adults: booking.nb_adults,
                nb_childs: booking.nb_childs,
                menage: booking.menage,
                price: booking.price ? Number(String(booking.price).replace(/,/, '.')) : null
            }
        }).then((response) => {
            if (response.data) {
                this.setState({
                    error_response: null,
                    in_date: null,
                    out_date: null,
                    menage: false
                });
                toast.update(id, {
                    render: 'Réservation créée avec succès',
                    type: toast.TYPE.SUCCESS,
                    isLoading: false,
                    autoClose: 4000
                });
                this.getInavailableDates();
                this.props.onSubmit();
            }
        }).catch((error) => {
            if (error.response.data) {
                this.setState({
                    error_response: error.response.data.message
                });
                toast.dismiss(id);
            } else {
                console.error(error.response);
                toast.update(id, {
                    render: 'Impossible de créer la réservation',
                    type: toast.TYPE.ERROR,
                    isLoading: false,
                    autoClose: 5000
                });
            }
        });
    }

    showStartMonth = () => {
        if (!this.state.in_date) return;
        if (moment(this.state.out_date).diff(moment(this.state.in_date), 'months') < 1) this.outDateCreate.current.getDayPicker().showMonth(this.state.in_date);
    }
    
    handleStartChange = (in_date, setFieldValue) => {
        if (in_date) in_date.setHours(0,0,0,0);
        this.setState({ in_date: in_date }, () => this.calculatePrice(setFieldValue));
    }
    
    handleEndChange = (out_date, setFieldValue) => {
        if (out_date) out_date.setHours(0,0,0,0);
        this.setState({ out_date: out_date }, () => {
            this.showStartMonth();
            this.calculatePrice(setFieldValue);
        });
    }

    handleMenageChange = (menage, setFieldValue) => {
        this.setState({ menage: menage }, () => this.calculatePrice(setFieldValue));
    }
    
    render() {
        const { in_date, out_date } = this.state;
        const modifiers = { start: in_date, end: out_date };
        let tomorrow = new Date();
        tomorrow.setDate(new Date().getDate() + 1);

        return (
            <div className="CreateBooking">
                <Formik
                    initialValues={{
                        name: "",
                        firstname: "",
                        in_date: "",
                        out_date: "",
                        address: "",
                        postal_code: "",
                        city: "",
                        country: "",
                        email: "",
                        phone: "",
                        nb_adults: 0,
                        nb_childs: 0,
                        menage: false,
                        price: ""
                    }}
                    validationSchema={
                        Yup.object().shape({
                            name: Yup.string()
                                .required("Nom requis"),
                            firstname: Yup.string()
                                .required("Prénom requis"),
                            in_date: Yup.date()
                                .transform(FormatManager.parseDateString)
                                .typeError("Veuillez saisir une date")
                                .required("Date d'entrée requise"),
                            out_date: Yup.date("Veuillez saisir une date")
                                .transform(FormatManager.parseDateString)
                                .typeError("Veuillez saisir une date")
                                .required("Date de sortie requise"),
                            address: Yup.string()
                                .required("Adresse requise"),
                            postal_code: Yup.string()
                                .required("Code postal requis"),
                            city: Yup.string()
                                .required("Ville requise"),
                            country: Yup.string()
                                .required("Pays requis"),
                            email: Yup.string()
                                .email("Veuillez saisir une adresse mail")
                                .required("Adresse mail requise"),
                            phone: Yup.lazy((value) => {
                                if (value) {
                                    return Yup.string()
                                        .phone(null, false, "Veuillez saisir un numéro de téléphone")
                                        .notRequired()
                                        .default(undefined);
                                } else {
                                    return Yup.mixed().notRequired();
                                }
                            }),
                            nb_adults: Yup.number("Veuillez saisir un nombre entier positif")
                                .integer("Veuillez saisir un nombre entier positif")
                                .min(0, "Veuillez saisir un nombre entier positif")
                                .required("Nombre d'adultes requis"),
                            nb_childs: Yup.number("Veuillez saisir un nombre entier positif")
                                .integer("Veuillez saisir un nombre entier positif")
                                .min(0, "Veuillez saisir un nombre entier positif")
                                .required("Nombre d'enfants requis"),
                            menage: Yup.boolean(),
                            price: Yup.number("Veuillez saisir un montant")
                                .transform((value, originalValue) => Number(String(originalValue).replace(/,/, ".")))
                                .typeError("Veuillez saisir un montant")
                                .positive("Veuillez saisir un montant positif")
                        })
                    }
                    onSubmit={this.onSubmit}
                >
                    {({ errors, touched, setFieldValue, handleChange }) => (
                        <Form className="create-form" autoComplete="off">
                            {
                                this.state.error_response
                                && <div className="form-error">{this.state.error_response}</div>
                            }
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Nom*</label>
                                    <div className={touched.name && errors.name ? "field error" : "field"}>
                                        <Icon fontSize="small">assignment_ind</Icon>
                                        <Field name="name" autoComplete="family-name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="name" />
                                </div>
                                <div className="form-group">
                                    <label>Prénom*</label>
                                    <div className={touched.firstname && errors.firstname ? "field error" : "field"}>
                                        <Icon fontSize="small">assignment_ind</Icon>
                                        <Field name="firstname" autoComplete="given-name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="firstname" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Date d'entrée*</label>
                                    <div className={touched.in_date && errors.in_date ? "field error" : "field"}>
                                        <Icon fontSize="small">assignment_turned_in</Icon>
                                        <DatePickerField
                                            name="in_date"
                                            value={in_date}
                                            dayPickerProps={{ 
                                                selectedDays: [in_date, { from: in_date, to: out_date }],
                                                disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: tomorrow, after: out_date }) : [],
                                                month: tomorrow,
                                                fromMonth: tomorrow,
                                                toMonth: out_date,
                                                modifiers: modifiers,
                                                onDayClick: () => this.outDateCreate.current.getInput().focus(),
                                                className: "dates-range"
                                            }}
                                            onDayChange={this.handleStartChange}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="in_date" />
                                </div>
                                <div className="form-group">
                                    <label>Date de sortie*</label>
                                    <div className={touched.out_date && errors.out_date ? "field error" : "field"}>
                                        <Icon fontSize="small">assignment_return</Icon>
                                        <DatePickerField
                                            name="out_date"
                                            ref={this.outDateCreate}
                                            value={out_date}
                                            dayPickerProps={{ 
                                                selectedDays: [in_date, { from: in_date, to: out_date }],
                                                disabledDays: this.state.inavailable_dates ? this.state.inavailable_dates.concat({ before: in_date || tomorrow }) : [],
                                                modifiers: modifiers,
                                                month: in_date || tomorrow,
                                                fromMonth: in_date || tomorrow,
                                                className: "dates-range"
                                            }}
                                            onDayChange={this.handleEndChange}
                                        />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="out_date" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Adresse*</label>
                                    <div className={touched.address && errors.address ? "field error" : "field"}>
                                        <Icon fontSize="small">business</Icon>
                                        <Field name="address" autoComplete="street-address" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="address" />
                                </div>
                                <div className="form-group">
                                    <label>Code postal*</label>
                                    <div className={touched.postal_code && errors.postal_code ? "field error" : "field"}>
                                        <Icon fontSize="small">markunread_mailbox</Icon>
                                        <Field name="postal_code" autoComplete="postal-code" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="postal_code" />
                                </div>
                                <div className="form-group">
                                    <label>Ville*</label>
                                    <div className={touched.city && errors.city ? "field error" : "field"}>
                                        <Icon fontSize="small">location_city</Icon>
                                        <Field name="city" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="city" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group">
                                    <label>Pays*</label>
                                    <div className={touched.country && errors.country ? "field error" : "field"}>
                                        <Icon fontSize="small">flag</Icon>
                                        <Field name="country" autoComplete="country-name" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="country" />
                                </div>
                                <div className="form-group">
                                    <label>Mail*</label>
                                    <div className={touched.email && errors.email ? "field error" : "field"}>
                                        <Icon fontSize="small">alternate_email</Icon>
                                        <Field name="email" autoComplete="email" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="email" />
                                </div>
                                <div className="form-group">
                                    <label>Téléphone*</label>
                                    <div className={touched.phone && errors.phone ? "field error" : "field"}>
                                        <Icon fontSize="small">phone</Icon>
                                        <PhoneInputField name="phone" autoComplete="tel" />
                                    </div>
                                    <ErrorMessage component="div" className="error" name="phone" />
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="stay-horizontal">
                                    <div className="form-group">
                                        <label>Adultes*</label>
                                        <div className={touched.nb_adults && errors.nb_adults ? "field error" : "field"}>
                                            <Icon fontSize="small">face</Icon>
                                            <Field name="nb_adults" type="number" />
                                        </div>
                                        <ErrorMessage component="div" className="error" name="nb_adults" />
                                    </div>
                                    <div className="form-group">
                                        <label>Enfants*</label>
                                        <div className={touched.nb_childs && errors.nb_childs ? "field error" : "field"}>
                                            <Icon fontSize="small">child_care</Icon>
                                            <Field name="nb_childs" type="number" />
                                        </div>
                                        <ErrorMessage component="div" className="error" name="nb_childs" />
                                    </div>
                                </div>
                            </div>
                            <div className="form-row">
                                <div className="form-group checkbox">
                                    <div className={touched.menage && errors.menage ? "field checkbox error" : "field checkbox"}>
                                        <label>
                                            <span className="checkbox-input">
                                                <span className="checkbox-label">Option ménage</span>
                                                <Field type="checkbox" name="menage" onChange={(e) => {
                                                    handleChange(e);
                                                    this.handleMenageChange(e.target.checked, setFieldValue);
                                                }}/>
                                                <span className="checkbox-control"><Icon fontSize="tiny">checked</Icon></span>
                                            </span>
                                        </label>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="menage" />
                                </div>
                                <div className="form-group">
                                    <label>Prix</label>
                                    <div className={touched.price && errors.price ? "field with-button error" : "field with-button"}>
                                        <Icon fontSize="small">euro</Icon>
                                        <Field name="price" />
                                        <button type="button" className="recalculate" onClick={(e) => this.onCalculateClick(e, setFieldValue)} >Calculer le prix</button>
                                    </div>
                                    <ErrorMessage component="div" className="error" name="price" />
                                </div>
                            </div>
                            <div className="buttons-container">
                                <button type="submit" disabled={Object.keys(errors).length > 0}>
                                    <Icon fontSize="small">save</Icon><span>Enregistrer</span>
                                </button>
                            </div>
                        </Form>
                    )}
                </Formik>
            </div>
        );
    }
}

export default CreateBooking;